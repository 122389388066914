import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FormattedPrice } from "../components/FormatValues";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const CarouselHeadline = (props) => {
  const { items } = props;

  const swiperRef = useRef(null);

  useEffect(() => {
    if (!swiperRef || !swiperRef?.current) return;

    const swiperInstance = swiperRef?.current?.swiper;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          swiperInstance.autoplay.start();
        } else {
          swiperInstance.autoplay.stop();
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(swiperInstance.el);
  }, []);

  const handleMouseEnter = () => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  return (
    <>
      {items.records && items.records.length > 0 && (
        <>
          <div className="container">
            <div className="testimonial">
              <div className="section-head book-align">
                <div>
                  <h2 className="title mb-0 m-r50">Cadernos das Manchetes</h2>
                </div>
                <div className="pagination-align style-1">
                  <div className="testimonial-button-prev swiper-button-prev">
                    <i className="fa-solid fa-angle-left"></i>
                  </div>
                  <div className="testimonial-button-next swiper-button-next">
                    <i className="fa-solid fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="testimonial-slider-container p-l20 p-r20"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Swiper
              ref={swiperRef}
              slidesPerView={3}
              spaceBetween={30}
              loop={false}
              navigation={{
                prevEl: ".testimonial-button-prev",
                nextEl: ".testimonial-button-next",
              }}
              autoplay={{ delay: 4000 }}
              initialSlide={2}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
            >
              {items.records.map((item, ind) => (
                <SwiperSlide key={ind}>
                  <div className="headline-carousel">
                    <div className=" testimonial-1 m-0">
                      <div className="testimonial-info">
                        <div className="testimonial-text">
                          <p>
                            {item.sku} - {item.name}
                          </p>
                        </div>
                        <div className="testimonial-detail">
                          <div className="testimonial-pic">
                            <img
                              src={item.photos[0] && item.photos[0]?.photo?.url}
                              alt=""
                            />
                          </div>
                          <div className="info-right">
                            <h6 className="testimonial-name">
                              <FormattedPrice price={item.amount} />
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
};

export default CarouselHeadline;

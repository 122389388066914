import React, { useState, useEffect, useContext, useCallback } from "react";
import AuthContext from "../../../contexts/auth";
import showPopup from "../../PopUp";
import api from "../../../services/config.url";
import arrowDown from "../../../assets/images/arrowDown.png";
import { useForm } from "react-hook-form";

export default function RevisionsQuestions() {
  const { setLoading } = useContext(AuthContext);
  const { register, handleSubmit, reset } = useForm();
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [response, setResponse] = useState([]);
  const [children, setChildren] = useState([]);
  const [products, setProducts] = useState([]);
  const [revisions, setRevisions] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [titleQuestion, setTitleQuestion] = useState("");
  const [activeSubContainerQc, setActiveSubContainerQc] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValueQc, setSearchValueQc] = useState("");
  const [subQuestionId, setSubQuestionId] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [filteredDataQc, setFilteredDataQc] = useState(products);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api.get(`/customers/revisions-questions`);
      setResponse(res.data);
      setActiveSubContainerQc(null);
      setActiveAccordion(null);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de informativos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchChildren = useCallback(
    async (item) => {
      if (!item) return;
      try {
        setIsLoadingContainer(true);
        const response = await api.get(
          `/customers/revisions-questions/${item?.parentId}`
        );
        setChildren(response.data);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao carregar a tabela de revisões";
        showPopup(BackendError || genericError, "error");
      } finally {
        setIsLoadingContainer(false);
      }
    },
    [activeAccordion]
  );

  useEffect(() => {
    fetchChildren();
  }, [fetchChildren, setItemSelected, itemSelected, activeAccordion]);

  useEffect(() => {
    getDropDownProducts();
  }, []);

  useEffect(() => {
    setSearchValueQc("");
  }, [activeSubContainerQc]);

  const getDropDownProducts = async () => {
    try {
      const list = await api.get(
        `/customers/drop-down-lists?modelName=Product`
      );
      list.data.sort((a, b) => a.sku - b.sku);

      const dropDown = list.data.map((x) => ({
        label: `${x.sku} - ${x?.name}`,
        value: JSON.stringify(x),
      }));
      setProducts(list.data);
      setFilteredDataQc(dropDown);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de produtos";
      showPopup(BackendError || genericError, "error");
    }
  };
  const handleAccordionClick = async (index, item) => {
    setSearchValueQc("");
    if (item === activeAccordion) {
      setActiveAccordion(null);
      setChildren([]);
    } else {
      setChildren([]);
      setItemSelected(item);
      setActiveAccordion(index);
      await fetchChildren(item);
    }
  };
  const deleteQuestion = async (children) => {
    try {
      setLoading(true);
      await api.delete(`/customers/revisions-questions/${children._id}`);
      showPopup("Questão deletada com sucesso", "success");
      await fetchData();
      await fetchChildren(children);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao deletar uma questão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };
  const updateQuestion = async (item, targetValue, type) => {
    if (type === "item" && Number(targetValue) < 1) return;

    const { _id, qtdQuestion, observation, subject, parent } = item;
    let payload = { ...item, parent: parent._id };

    if (type === "spots") {
      if (qtdQuestion === Number(targetValue)) return;
      payload.qtdQuestions = Number(targetValue);
    }
    if (type === "observation") {
      if (observation === targetValue) return;
      payload.observation = targetValue;
    }
    if (type === "subject") {
      if (subject === targetValue) return;
      payload.subject = targetValue;
    }

    const updateItem = async () => {
      try {
        setLoading(true);
        const response = await api.patch(
          `/customers/revisions-questions/${_id}`,
          payload
        );
        showPopup("Questão atualizada", "success");
        setRevisions(response.data);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao editar uma questão";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
        if (type === "spots") {
          await fetchData();
        }
      }
    };
    updateItem();
  };
  const postSubQuestion = async (data) => {
    if (!data.subject || !searchValueQc) {
      return showPopup(
        "Preencha os campos 'Assunto' e 'Quantidade de questões'",
        "error"
      );
    }
    const payload = {
      subject: searchValueQc,
      qtdQuestions: Number(data.questionQc),
      observation: data.observationQc,
      parent: itemSelected?.parent?._id,
      parentId: itemSelected?.parentId,
    };

    try {
      setLoading(true);
      await api.post(`/customers/revisions-questions`, payload);
      setActiveSubContainerQc(null);
      setActiveAccordion(null);
      showPopup("Criado com sucesso", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao criar uma questão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      await fetchData();
      reset();
    }
  };
  const postQuestion = async () => {
    if (!titleQuestion) {
      return showPopup("Preencha com o nome da matéria", "error");
    }

    const payload = {
      name: titleQuestion,
      spots: 0,
      observation: null,
      isParent: true,
    };

    try {
      setLoading(true);
      await api.post(`/customers/revisions-questions`, payload);
      // setResponse(response.data);
      showPopup("Criado com sucesso", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao criar uma questão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      await fetchData();
      reset();
    }
  };
  const handleSearchQc = (event, item) => {
    setSearchValueQc(event);
    const newFilter = products.filter(
      (product) =>
        (product.name.toLowerCase().includes(event.toLowerCase()) ||
          product.sku.toLowerCase().includes(event.toLowerCase())) &&
        product?.parent?.toString() === item?.parent?._id?.toString()
    );
    setFilteredDataQc(newFilter);
  };
  const haddleSubQuestionValue = (product) => {
    setSearchValueQc(`${product.sku} - ${product.name}`);
    setFilteredDataQc([]);
  };

  return (
    <>
      <div className="container-itens-qc">
        {response.length > 0 ? (
          <>
            {response.map((item, index) => (
              <span key={index}>
                <div
                  className={`${
                    item.spots > 0 ? "withItens" : "noItens"
                  } container-qc`}
                >
                  <div className="counter-spots">
                    <span className="bolinhaCount">{item.spots}</span>{" "}
                    <span>QC</span>{" "}
                  </div>
                  <div className="container-right">
                    <span>{item.name}</span>
                    <div className="container-icons-qc">
                      <img
                        src={arrowDown}
                        width={24}
                        onClick={() => handleAccordionClick(index, item)}
                      />
                      <button
                        className="btnCad deleteBtnCad btnhover"
                        onClick={() => deleteQuestion(item)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>

                {activeAccordion == index && (
                  <div className="containerSubQuestion">
                    <form onSubmit={handleSubmit(postSubQuestion)}>
                      <div className="subContainer-qc-input">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Assunto..."
                          {...register("subject")}
                          value={searchValueQc}
                          onChange={(e) => {
                            handleSearchQc(e.target.value, item);
                          }}
                        />
                        {searchValueQc.length > 0 &&
                          filteredDataQc.length > 0 && (
                            <ul className="list-filtered-qc">
                              {filteredDataQc.map((product, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    haddleSubQuestionValue(product)
                                  }
                                >
                                  {product.sku} - {product.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        <input
                          type="number"
                          autoComplete="off"
                          min={0}
                          placeholder="Quantidade de questões..."
                          {...register("questionQc")}
                        />
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Observações..."
                          {...register("observationQc")}
                        />
                        <button
                          type="submit"
                          className=" btn-primary btnhover"
                          onClick={() => setSubQuestionId(item.id)}
                        >
                          Salvar
                        </button>
                      </div>
                    </form>
                    {activeAccordion === index && children.length > 0 ? (
                      <>
                        {children.map((children, index) => (
                          <React.Fragment key={index}>
                            <div className="subQuestions">
                              <span className="element-1-qc">
                                <span className="title-subQuestions">
                                  Assunto:
                                </span>{" "}
                                <input
                                  onChange={(e) => e.target.value}
                                  type="text"
                                  className="input_questions subject-size-input p-1"
                                  defaultValue={children.subject || 0}
                                  onBlur={(e) =>
                                    updateQuestion(
                                      children,
                                      e.target.value,
                                      "subject"
                                    )
                                  }
                                />
                              </span>
                              <span className="element-2-qc">
                                <span className="title-subQuestions">
                                  Quantidade de Questões:
                                </span>{" "}
                                <input
                                  onChange={(e) => e.target.value}
                                  type="number"
                                  min="0"
                                  className="input_questions qtd_questions_size p-1"
                                  defaultValue={children.qtdQuestions || 0}
                                  onBlur={(e) =>
                                    updateQuestion(
                                      children,
                                      e.target.value,
                                      "spots"
                                    )
                                  }
                                />
                              </span>
                              <span className="element-3-qc">
                                <span className="title-subQuestions">
                                  {" "}
                                  Observações:
                                </span>{" "}
                                <input
                                  className="input_questions observation-size-input p-1"
                                  onBlur={(e) =>
                                    updateQuestion(
                                      children,
                                      e.target.value,
                                      "observation"
                                    )
                                  }
                                  defaultValue={
                                    children.observation
                                      ? children.observation
                                      : "vazio"
                                  }
                                />
                              </span>
                              <div className="element-4-qc">
                                <button
                                  className="btnCad deleteBtnCad btnhover "
                                  onClick={() => deleteQuestion(children)}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <>
                        {isLoadingContainer && (
                          <div
                            style={{ margin: "20px auto" }}
                            className="loadingContainer"
                          ></div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </span>
            ))}
          </>
        ) : (
          <>
            <p className="noSubject">vazio</p>
          </>
        )}
      </div>

      <div className="container-qc-new">
        <input
          type="text"
          placeholder="Adicionar uma matéria..."
          onChange={(e) => setTitleQuestion(e.target.value)}
        />
        <button
          type="submit"
          className=" btn-primary btnhover"
          onClick={() => postQuestion()}
        >
          Salvar
        </button>
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import api from "../services/config.url";
import showPopup from "../components/PopUp";
import AuthContext from "../contexts/auth";
import ModalCollaboration from "../components/modal/modalMyCollaborations";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/pt-br";
dayjs.extend(utc);
dayjs.extend(timezone);
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function MyCollaborations() {
  const [currentPage, setCurrentPage] = useState(1);

  const { setLoading } = useContext(AuthContext);
  const [openCollaborationsModal, setOpenCollaborationsModal] = useState(false);
  const [filterCollaboration, setFilterCollaboration] = useState({});
  const [response, setResponse] = useState([]);
  const [query, setQuery] = useState({
    current: currentPage,
    pageSize: 10,
    groupBy: false,
  });

  useEffect(() => {
    const formattedQuery = new URLSearchParams(query).toString();
    setLoading(true);
    api
      .get(`/customers/message/feedbacks?${formattedQuery}`)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível concluir a busca por CEP";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  const handleModal = (colaboration) => {
    setFilterCollaboration(colaboration);
    setOpenCollaborationsModal(true);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };

  const ResumeCollaborations = () => {
    if (!response.records || response.records.length === 0) {
      return (
        <div className="emptyCart">
          <div>vazio</div>
        </div>
      );
    }
    return (
      <>
        <h5>
          Total de colaborações:<span> {response?.total}</span>
        </h5>
        <table className="table-bordered check-tbl mb-4">
          <thead>
            <tr>
              <th>Data</th>
              <th>Caderno</th>
              <th></th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody className="orders">
            {response?.records.map((colaboration, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    {dayjs
                      .tz(colaboration.createdAt, "America/Sao_Paulo")
                      .format("DD/MM/YYYY")}
                  </td>
                  <td colSpan={2}>
                    {`${colaboration?.productId?.sku} - ${colaboration?.productId?.name}`}
                  </td>
                  <td className="text-center">
                    <a
                      onClick={() => handleModal(colaboration)}
                      className="d-flex justify-content-center downloadPdfBtn btn btn-primary btnhover max-w100"
                      target="_blank"
                    >
                      Visualizar
                    </a>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="header-padding-main">
      <div className="container m-t100">
        <ModalCollaboration
          open={openCollaborationsModal}
          onClose={() => setOpenCollaborationsModal(false)}
          date={filterCollaboration.date}
          data={filterCollaboration}
        />
        <div className="col-lg-12 m-b100">
          <div className="widget">
            <h4 className="widget-title ">Minhas colaborações</h4>

            <ResumeCollaborations />
          </div>
        </div>
        <div className="pagination-component">
          <Stack spacing={2}>
            <Pagination
              count={response.pages}
              page={currentPage}
              onChange={(event, page) => handlePagination(page)}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}

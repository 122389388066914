import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import api from "../../services/config.url";
import showPopup from "../../components/PopUp";
import AuthContext from "../../contexts/auth";
import JSZip from "jszip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import { saveAs } from "file-saver";

export default function BooksTable() {
  const { cart, setLoading, setSizeCart, user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [pageSize, setPageSize] = useState(200);
  const sizeCart = useMemo(() => cart?.items?.length, [cart]);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  useEffect(() => {
    if (!cart) return;
    setSizeCart(sizeCart);
    setIsLoadingContainer(true);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/customers/orders/group?current=1&pageSize=${pageSize}&status=PAYMENT_APPROVED`
        );
        setOrders(response.data.records);
        setIsLoadingContainer(false);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao listar os cadernos adquiridos";
        showPopup(BackendError || genericError, "error");
      } finally {
        setIsLoadingContainer(false);
      }
    };
    fetchData();
  }, [cart, pageSize]);

  const showMore = () => {
    if (pageSize > orders.length + 2) {
      showPopup("Não há mais produtos para carregar", "error");
    }
    setPageSize(pageSize + 2);
  };

  const handleDownload = async (obj) => {
    const productId = obj._id ? obj._id : obj.product._id;

    try {
      setLoading(true);
      const zip = new JSZip();
      const response = await api.get(`/customers/products/pdf/${productId}`);

      const downloadPromises = response.data.map(async (item) => {
        const response = await fetch(item.url);
        const data = await response.blob();
        const fileName = `${item.name}.pdf`.replace(/[\\/:"*?<>|]/g, "_");
        zip.file(fileName, data);
      });

      await Promise.all(downloadPromises);

      const content = await zip.generateAsync({ type: "blob" });
      const fileURL = window.URL.createObjectURL(content);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "Livros.zip");
      link.click();
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao baixar os arquivos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCloud = async (obj) => {
    const sku = obj.sku ?? obj.product?.sku;
    const name = user?.name;
    const secondName = user?.secondName;
    const documentCustomer = user?.document;
    const fileName = `${obj.name ?? obj.product?.name}.pdf`.replace(
      /[\\/:"*?<>|]/g,
      "_"
    );

    if (!name) {
      showPopup(
        "O campo Nome é obrigatório! Acesse seu perfil para preenchê-lo.",
        "error"
      );
      return;
    }
    if (!secondName) {
      showPopup(
        "O campo Sobrenome é obrigatório! Acesse seu perfil para preenchê-lo.",
        "error"
      );
      return;
    }
    if (!documentCustomer) {
      showPopup(
        "O campo CPF é obrigatório! Acesse seu perfil para preenchê-lo.",
        "error"
      );
      return;
    }
    try {
      setLoading(true);
      const response = await api.post(
        `/customers/products/cloud-pdf`,
        {
          sku,
          customerName: `${name} ${secondName}`,
          customerCPF: documentCustomer,
        },
        { "Content-Type": "application/pdf" }
      );
      const pdfUrl = response.data.downloadUrl;
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao baixar o arquivo");
          }
          return response.blob();
        })
        .then((blob) => {
          saveAs(blob, `${response.data.fileName}.pdf`);
        })
        .catch((error) => {
          console.error("Erro:", error);
        });
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao baixar os arquivos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="topo-quadros">
        <h3 className="title-table size-title-table"> CADERNOS ADQUIRIDOS </h3>
        <div>
          <Link className=" cart" to={"carrinho"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="40px"
              viewBox="0 0 18 24"
              width="40px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
            </svg>
            <span className="badge">{sizeCart}</span>
          </Link>
          <Link className="button-score-header" to={"lista-de-cadernos"}>
            <button className="btn btn-primary  btnhover m-b10">
              Compre outros cadernos aqui
            </button>
          </Link>
        </div>
      </div>

      <CAccordion>
        <CAccordionItem itemKey={1}>
          <div className="table-responsive">
            <div className="table check-tbl">
              <CAccordionHeader className="bg-my-books-collapse">
                <div className="title-of-table ">
                  <div>MEUS CADERNOS</div>
                </div>
              </CAccordionHeader>
              <CAccordionBody className="p-0">
                <div className="lines-of-table">
                  {orders && orders.length < 1 ? (
                    !isLoadingContainer ? (
                      <div className="p-3">
                        Você não possui nenhum pedido com status de pagamento
                        aprovado.
                      </div>
                    ) : (
                      <div className="loadingContainer m-3"></div>
                    )
                  ) : (
                    <CAccordion>
                      {orders.map((order, index) => (
                        <CAccordionItem
                          itemKey={index}
                          key={index}
                          className="p-0"
                        >
                          <React.Fragment key={index}>
                            <CAccordionHeader
                              className={
                                (order?.items && order.items.length < 1) ||
                                !order?.items
                                  ? "removeIcon espacinho"
                                  : ""
                              }
                            >
                              <div className="d-flex justify-content-between w-100">
                                <div>
                                  {order?.product ? (
                                    <React.Fragment>
                                      {order?.product?.sku} -{" "}
                                      {order.product?.name}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div
                                        style={{
                                          marginBottom: "9px",
                                          marginTop: "4px",
                                        }}
                                      >
                                        {order?.name}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                                <div className="ml-auto m-r20 p-l10">
                                  {(order.product?.parent !== null ||
                                    order?.items.length < 1) && (
                                    <input
                                      type="button"
                                      onClick={() => handleDownloadCloud(order)}
                                      className="d-flex justify-content-center downloadPdfBtn btn btn-primary btnhover max-w100 p-2"
                                      target="_blank"
                                      style={{ alignItems: "center" }}
                                      value={"Download"}
                                    />
                                  )}
                                </div>
                              </div>
                            </CAccordionHeader>
                            <CAccordionBody className="p-0">
                              {order.items && order.items.length > 0 ? (
                                <React.Fragment>
                                  {order.items.map((child, index) => (
                                    <React.Fragment key={index}>
                                      <div className="p-3 flex-my-books">
                                        <div className="product-item-name item-1">
                                          {child?.product ? (
                                            <React.Fragment>
                                              {child?.product?.sku} -
                                              {child.product?.name}
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {child?.sku} - {child?.name}
                                            </React.Fragment>
                                          )}
                                        </div>
                                        <div className="item-3">
                                          <input
                                            type="button"
                                            onClick={() =>
                                              handleDownloadCloud(child)
                                            }
                                            className="d-flex justify-content-center downloadPdfBtn btn btn-primary btnhover max-w100 p-2"
                                            target="_blank"
                                            value={"Download"}
                                          />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </CAccordionBody>
                          </React.Fragment>
                        </CAccordionItem>
                      ))}
                    </CAccordion>
                  )}
                </div>
              </CAccordionBody>
            </div>
            <div></div>
          </div>
        </CAccordionItem>
      </CAccordion>
      {/* {pageSize < orders.length && (
        <section className="container box-main">
          <div className="btn-book">
            <button onClick={showMore}>Carregar mais</button>
          </div>
        </section>
      )} */}
    </>
  );
}

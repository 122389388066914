import React, { useContext, useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
//Components
import PageTitle from "./../layouts/PageTitle";
import TestimonialSlider from "./../components/Home/TestimonialSlider";
import NewsLetter from "../components/NewsLetter";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import api from "../services/config.url";
/*
 COLLAPSE IN THIS URL : https://coreui.io/react/docs/getting-started/introduction/
*/

function AboutUs() {
  const [response, setResponse] = useState([]);
  const { setLoading } = useContext(AuthContext);

  const getAboutUs = async () => {
    setLoading(true);
    try {
      const response = await api.get("customers/global/about-us");
      setResponse(response.data);
    } catch (error) {
      const genericError = "Erro ao carregar a página (Sobre nós) ";
      return showPopup(genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAboutUs();
  }, []);

  const ListOfItems = () => {
    return response.map((x, index) => {
      return (
        <CAccordion key={index}>
          <CAccordionItem itemKey={x.order}>
            <CAccordionHeader>{x.name}</CAccordionHeader>
            <CAccordionBody>
              <div dangerouslySetInnerHTML={{ __html: x.description }}></div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      );
    });
  };

  return (
    <div className="header-padding-main">
      <div className="page-content bg-white">
        <PageTitle parentPage="CJ" childPage="Sobre nós" />

        <section className="container m-t100 box-about-us">
          <ListOfItems />
        </section>
        <section className="content-inner-1 testimonial-wrapper">
          <TestimonialSlider />
        </section>

        <NewsLetter />
      </div>
    </div>
  );
}
export default AboutUs;

import React, { useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const ModalPdf = ({ isModalOpen, isModalClose, pdf }) => {
  useEffect(() => {
    !isModalOpen && isModalClose(false);
  }, [isModalOpen, isModalClose]);

  if (!pdf) return null;
  // const base64 = `data:application/pdf;base64,${pdf}`;

  // const base64toBlob = (data) => {
  //   const base64WithoutPrefix = data.substr(
  //     "data:application/pdf;base64,".length
  //   );

  //   const bytes = window.atob(base64WithoutPrefix);
  //   let length = bytes.length;

  //   let out = new Uint8Array(length);

  //   while (length--) {
  //     out[length] = bytes.charCodeAt(length);
  //   }

  //   return new Blob([out], { type: "application/pdf" });
  // };

  // const blob = base64toBlob(base64);
  // const url = URL.createObjectURL(blob);
  return (
    <>
      {isModalOpen && pdf && (
        <div className="background-black">
          <div className="container-pdf">
            <div className="close-pdf" onClick={() => isModalClose(false)}>
              <p>x</p>
            </div>
            <div id="pdf-container"></div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer fileUrl={pdf} withCredentials={false} />
            </Worker>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalPdf;

// import React, { useEffect } from "react";
// import jsPDF from "jspdf";

// const ModalPdf = ({ isModalOpen, isModalClose, pdf }) => {
//   useEffect(() => {
//     !isModalOpen && isModalClose(false);
//   }, [isModalOpen, isModalClose]);

//   const base64 = `data:application/pdf;base64,${pdf}`;

//   useEffect(() => {
//     const generatePdfBlobUrl = (base64) => {
//       return new Blob([blob], { type: "application/pdf" });
//     };

//     const pdfBlobUrl = generatePdfBlobUrl(base64Data);

//     const iframe = document.createElement("iframe");
//     iframe.width = "100%";
//     iframe.height = "100%";
//     iframe.src = pdfBlobUrl;

//     const x = document.getElementById("pdf-container");
//     x.appendChild(iframe);

//     return () => {
//       URL.revokeObjectURL(pdfBlobUrl); // Limpeza após desmontagem do componente
//     };
//   }, [base64Data]);

//   return (
//     <>
//       {isModalOpen && pdf && (
//         <div className="background-black">
//           <div className="container-pdf">
//             <div className="close-pdf" onClick={() => isModalClose(false)}>
//               <p>x</p>
//             </div>
//             <div id="pdf-container"></div>;
//             {/* <iframe
//               title="PDF Preview"
//               src={pdfBlobUrl}
//               style={{ width: "100%", height: "100%" }}
//             ></iframe> */}
//             {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
//               <Viewer
//                 fileUrl={pdf}
//                 withCredentials={false}
//                 plugins={[defaultLayoutPluginInstance]}
//               />
//             </Worker> */}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ModalPdf;

import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/pt-br";
dayjs.extend(utc);
dayjs.extend(timezone);

const InputDate = (props) => {
  const { item, updateCurrent, className, action = true } = props;

  const [date, setDate] = useState(null);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (item.currentDate) {
      const formattedDate = dayjs(item.currentDate).isValid()
        ? dayjs(item.currentDate).format("DD/MM/YYYY")
        : item.currentDate;
      setDate(formattedDate);
    }
  }, [item.currentDate]);

  const onSave = () => {
    if (date.length === 0 || date.length === 10) {
      let newDate = null;
      if (date.length === 10) {
        newDate = dayjs(date, "DD/MM/YYYY").toDate();
      }
      const old = item.currentDate ? dayjs(item.currentDate) : null;

      const dates = JSON.stringify({ oldDate: old, newDate });
      setCanSave(false);
      setDate(null);
      const body = {
        id: item._id,
        data: dates,
        item: item,
      };

      updateCurrent(body);
    }
  };

  const handleUpdate = (date) => {
    setDate(date);
    if (date.length === 10 || date.length === 0) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  return (
    <div className="relative">
      {action && canSave && (
        <button
          className="btn-save-cad text-center btn-primary"
          onClick={() => onSave()}
        >
          ok
        </button>
      )}
      <InputMask
        className={`${className}`}
        mask="99/99/9999"
        maskChar={null}
        placeholder="Preencha..."
        size="sm"
        value={date}
        borderRadius={5}
        onChange={(e) => {
          handleUpdate(e.target.value);
        }}
      />
    </div>
  );
};

export default InputDate;

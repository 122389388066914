import React, { useState, useEffect, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import AuthContext from "../../../contexts/auth";
import showPopup from "../../PopUp";
import CreateInfoModal from "../modal-informative/create-revisions-info";
import api from "../../../services/config.url";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import CaccordionHeader from "./caccordion-header";
import ConfirmationModal from "../modalConfirmation";

export default function RevisionsModal({}) {
  const { register, handleSubmit } = useForm();
  const { setLoading } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [response, setResponse] = useState([]);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState();
  const [messageDelete, setMessageDelete] = useState("");
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingContainer(true);
      setResponse([]);
      const res = await api.get(`/customers/revisions-info`);
      setResponse(res.data);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de informativos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
    }
  }, [setIsLoadingContainer]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const fetchChildren = async (item) => {
  //   if (!itemSelected) return;
  //   try {
  //     setChildren([]);
  //     setIsLoadingContainer(true);
  //     const response = await api.get(`/customers/revisions-info/${item?._id}`);
  //     setChildren(response.data);
  //   } catch (error) {
  //     const BackendError = error?.response?.data?.message;
  //     const genericError = "Erro ao carregar a lista de informativos";
  //     showPopup(BackendError || genericError, "error");
  //   } finally {
  //     setIsLoadingContainer(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchChildren(itemSelected);
  // }, [setItemSelected, itemSelected]);

  const deleteInfo = async (id) => {
    try {
      setIsLoadingContainer(true);
      await api.delete(`/customers/revisions-info/${id}`);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Ocorreu um erro ao deletar esta revisão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      // fetchChildren(itemSelected);
    }
  };

  const deleteCourt = async (id) => {
    try {
      setIsLoadingContainer(true);
      await api.delete(`/customers/revisions-info/${id}/court`);
      showPopup("Tribunal deletado com sucesso", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Ocorreu um erro ao deletar esta revisão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      await fetchData();
    }
  };

  const updateInfo = async (item, value) => {
    let payload = {
      ...item,
      observation: value,
    };
    try {
      setIsLoadingContainer(true);
      await api.patch(`/customers/revisions-info/${item._id}`, payload);
      showPopup("Informativo atualizado", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao editar um informativo";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      // fetchChildren(itemSelected);
    }
  };

  const onSubmit = async (data) => {
    const { from, to, court } = data;

    if (!from || !court) {
      return showPopup("Preencha os campos antes de criar", "alert");
    }
    if (court.length > 7) {
      return showPopup(
        "O tribunal deve conter no máximo 7 caracteres",
        "alert"
      );
    }

    if (
      (Number(from) > Number(to) || Number(from) === Number(to)) &&
      Number(to)
    ) {
      return showPopup(
        "O valor 'Dê' deve ser maior que o valor de 'Até'",
        "alert"
      );
    }
    const payload = {
      ...data,
      to: !data.to ? null : data.to,
      parent: null,
      observation: null,
    };

    try {
      setLoading(true);
      await api.post(`/customers/revisions-info`, payload);
      const message = "Novo informativo criado com sucesso";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de informativos";
      showPopup(BackendError || genericError, "error");
    } finally {
      await fetchData();
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (typeToDelete.delete === "COURT") {
      deleteCourt(typeToDelete.item);
    } else {
      deleteInfo(typeToDelete.item);
    }
    setIsOpenConfirmation(false);
  };

  const handleClose = () => {
    setIsOpenConfirmation(false);
  };

  const handleCancel = useCallback(async () => {
    setIsOpen(false);
    await fetchChildren(itemSelected);
  }, [isOpen]);

  // const handleAccordionClick = (index, item) => {
  //   setItemSelected(item);
  //   setActiveAccordion(index === activeAccordion ? null : index);
  //   if (item) {
  //     fetchChildren(item);
  //   }
  // };

  const handleDeleteFunction = (value) => {
    setIsOpenConfirmation(true);
    setTypeToDelete({ item: value.id, delete: "COURT" });
    setMessageDelete(`Deseja deletar todos os informativos de ${value.court}?`);
  };

  return (
    <div>
      <ConfirmationModal
        message={messageDelete}
        onConfirm={handleDelete}
        onCancel={handleClose}
        isModalOpen={isOpenConfirmation}
      />
      <CreateInfoModal
        onCancel={handleCancel}
        isModalOpen={isOpen}
        parent={itemSelected}
      />
      <form
        className="form-informative m-b20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className="container-revision flex-container"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="info-header top-1">
            <input
              name="court"
              {...register("court")}
              placeholder={"Tribunal..."}
              className="upperCase"
            />
            <div> N° </div>
            <input
              type="number"
              placeholder={"de..."}
              min="0"
              autoComplete="off"
              defaultValue="test"
              name="from"
              {...register("from")}
              maxLength="3"
            />
            <div> a </div>
            <input
              type="number"
              placeholder={"até..."}
              min="0"
              autoComplete="off"
              name="to"
              {...register("to")}
              maxLength="5"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <button
              name="submit"
              type="submit"
              className="btn btn-primary btnhover btn-at"
              style={{ width: "100%", height: "30px" }}
            >
              Criar
            </button>
          </div>
        </div>
      </form>
      <div className="container-informative-itens info-geral">
        <CAccordion>
          {response &&
            response.map((item, index) => (
              <CAccordionItem
                itemKey={index}
                key={item._id}
                className="m-b10"
                style={{
                  borderRadius: "6px",
                  borderColor: "#d5d5d5",
                }}
              >
                <CAccordionHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>{item.court}</div>
                    <div>
                      <button
                        style={{
                          width: "36px",
                          height: "33px",
                          marginRight: "20px",
                        }}
                        className="btnCad deleteBtnCad btnhover p-0"
                        onClick={() => handleDeleteFunction(item)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </CAccordionHeader>

                <CAccordionBody>
                  {item.infos?.length > 0 ? (
                    <>
                      {item.infos.map((info, index) => {
                        return (
                          <CaccordionHeader
                            item={info}
                            index={index}
                            refetch={fetchData}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div>vazio</div>
                    </>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            ))}
        </CAccordion>
      </div>
    </div>
  );
}

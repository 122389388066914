import React, { useContext, useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
//Components
import PageTitle from "../layouts/PageTitle";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import api from "../services/config.url";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ModalPdf from "../components/modal/modalPdf";
import GlobalContext from "../contexts/global";
import CarouselHeadline from "../components/CarouselHeadline";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Tips() {
  const [currentPage, setCurrentPage] = useState(1);
  const { setLoading } = useContext(AuthContext);
  const { global } = useContext(GlobalContext);
  const [response, setResponse] = useState([]);
  const [productsHeadline, setProductsHeadline] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [pdf, setPdf] = useState("");
  const [query, setQuery] = useState({
    current: currentPage,
    pageSize: 10,
  });

  useEffect(() => {
    const formattedQuery = new URLSearchParams({
      current: currentPage,
      pageSize: 500,
    }).toString();

    setLoading(true);

    api
      .get(`/customers/headlines/products?${formattedQuery}`)
      .then((response) => {
        setProductsHeadline(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível listar as Manchetes";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    const formattedQuery = new URLSearchParams(query).toString();
    setLoading(true);
    api
      .get(`/customers/headlines2/all?${formattedQuery}`)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível listar as Manchetes";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };

  const HeadlineList = () => {
    if (!response.records || response.records.length === 0) {
      return (
        <div className="emptyCart">
          <div>vazio</div>
        </div>
      );
    }

    return (
      <>
        {response?.records.map((headline, index) => (
          <React.Fragment key={index}>
            <CAccordion>
              <CAccordionItem itemKey={1} className="m-b10">
                <CAccordionHeader>{headline.title}</CAccordionHeader>
                <CAccordionBody style={{ padding: " 10px 10px 10px 10px" }}>
                  <div className="container-saude">
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: headline.description,
                      }}
                    ></div> */}
                    <ReactQuill
                      theme="bubble"
                      value={headline.description}
                      modules={{ toolbar: false }}

                      // onChange={setValue}
                    />
                    {headline.url && (
                      <div className="iFrame">
                        <iframe
                          height="auto"
                          width={"100%"}
                          src={headline.url}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className="header-padding-main">
      <div className="page-content bg-white">
        <PageTitle
          parentPage="CJ"
          childPage={global?.headerOptions?.find((x) => x.type === "TIP")?.name}
        />

        <ModalPdf
          pdf={pdf}
          isModalOpen={isOpen}
          isModalClose={() => setIsOpen(false)}
        />
        <section className="content-inner-2 m-b30 testimonial-wrapper">
          <CarouselHeadline items={productsHeadline} />
        </section>

        <section className="container m-t100 box-about-us">
          <div className="col-lg-12 m-b100">
            <HeadlineList />
          </div>
          <div className="pagination-component">
            <Stack spacing={2}>
              <Pagination
                count={response.pages}
                page={currentPage}
                onChange={(event, page) => handlePagination(page)}
              />
            </Stack>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Tips;

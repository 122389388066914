import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import api from "../services/config.url";
import { useHistory } from "react-router-dom";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";
import AuthContext from "../contexts/auth";
//Components
import PageTitle from "./../layouts/PageTitle";

function Registration() {
  const { signIn } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [billingAddress, setBillingAddress] = useState({});
  const [cepAvaiable, setCepAvaiable] = useState(true);
  const navegate = useHistory();

  const createUser = async (data) => {
    const number = data.mobilePhone.replace(/[^\d]/g, "");

    if (number.length < 11) {
      return showPopup("Número de celular inválido", "error");
    }
    if (!billingAddress?.city?._id) {
      return showPopup(
        "Os dados de endereço não estão completos, contate o administrador do site",
        "error"
      );
    }
    if (!billingAddress?.address) {
      return showPopup("O endereço é obrigatório", "error");
    }
    if (!billingAddress?.neighborhood) {
      return showPopup("O bairro é obrigatório", "error");
    }
    if (!data.name) {
      return showPopup("O campo nome é obrigatório", "error");
    }

    const payload = {
      name: data.name,
      email: data.email,
      mobilePhone: number,
      nickname: data.nickname,
      password: data.password,
      billingAddress: { ...billingAddress, city: billingAddress.city._id },
    };

    setLoading(true);
    await api
      .post("/customers/auth/signup", payload)
      .then((response) => {
        setTimeout(() => {
          navegate.push("/acesso");
        }, 1000);
        setLoading(false);
        showPopup("Cadastrado com sucesso!", "success");
      })
      .catch((error) => {
        setLoading(false);
        showPopup(error.response.data.message, "error");
      })
      .finally(() => {
        signIn(payload, { redirection: true }, "/perfil");
      });
  };

  const zipCode = async () => {
    if (billingAddress.zipCode.length < 8) {
      return showPopup("Preencha o campo 'CEP' corretamente.", "error");
    }

    setLoading(true);
    await api
      .get(`/customers/zip-codes/${billingAddress.zipCode}`)
      .then((response) => {
        setBillingAddress(response.data);
        !response.data.address.length > 0
          ? setCepAvaiable(false)
          : setCepAvaiable(true);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível concluir a busca por CEP";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="header-padding-main">
      <div className="page-content">
        {loading && (
          <div className="loader">
            <span className="loader__element"></span>
            <span className="loader__element"></span>
            <span className="loader__element"></span>
          </div>
        )}

        <PageTitle parentPage="CJ" childPage="Cadastre-se" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleSubmit(createUser)}>
                    <h4 className="text-secondary">Cadastre-se</h4>
                    <p className="font-weight-600">
                      Caso não possua uma conta, crie agora.
                    </p>
                    <div className="mb-4">
                      <label className="label-title">Nome completo *</label>
                      <input
                        {...register("name")}
                        required=""
                        autoComplete="false"
                        className="form-control"
                        placeholder="Seu nome completo"
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Usuário *</label>
                      <input
                        {...register("nickname")}
                        required=""
                        autoComplete="false"
                        className="form-control"
                        placeholder="Seu usuário"
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">E-mail *</label>
                      <input
                        {...register("email")}
                        required=""
                        autoComplete="false"
                        className="form-control"
                        placeholder="Seu endereço de E-mail"
                        type="email"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Telefone *</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        type="text"
                        className="form-control"
                        placeholder="Seu telefone"
                        {...register("mobilePhone")}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Senha *</label>
                      <input
                        {...register("password")}
                        required=""
                        className="form-control "
                        placeholder="Digite sua senha"
                        type="password"
                      />
                    </div>

                    {/* INICIO */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                          <label className="label-title">
                            Endereço completo *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={cepAvaiable}
                            value={billingAddress?.address}
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                          <label className="label-title">CEP *</label>
                          <InputMask
                            mask="99999999"
                            maskChar={null}
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={billingAddress?.zipCode || ""}
                            onBlur={() => zipCode()}
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                zipCode: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                          <label className="label-title">Bairro *</label>
                          <InputMask
                            type="text"
                            placeholder=""
                            className="form-control"
                            disabled={cepAvaiable}
                            value={billingAddress?.neighborhood || ""}
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                neighborhood: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                          <label className="label-title">Cidade *</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              (billingAddress &&
                                billingAddress?.city &&
                                billingAddress?.city?.name) ||
                              ""
                            }
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                city: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-3">
                          <label className="label-title">País *</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={billingAddress?.country || "Brasil"}
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                country: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* FIM */}

                    <div className="mb-5"></div>
                    <div className="text-left">
                      <button
                        type="submit"
                        className="btn btn-primary btnhover w-100 me-2"
                      >
                        Cadastrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Registration;

import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import ConfirmationModal from "./modalConfirmation";
import RevisionsBook from "./modal-revisions/revisions-book";
import RevisionsInfo from "./modal-informative/revisions-informative";
import RevisionsQuestions from "./modal-questions/revisions-question";
import api from "../../services/config.url";

export default function ModalControl({ open, onClose, valueTab }) {
  const [value, setValue] = useState(valueTab);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValue(valueTab);
  }, [valueTab]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    try {
      api.get(`/customers/revisions-questions/check`);
    } catch (error) {
      const genericError = "Você precisar estar logado";
      showPopup(genericError, "alert");
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  if (!open) return null;
  if (open) {
    document.body.style.overflow = "hidden";
  }
  if (!open) {
    document.body.style.overflow = "scroll";
    return null;
  }
  return (
    <>
      <div
        className="backgroundDark"
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="modal-control">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="Cadernos" value="1" />
                <Tab label="Informativos" value="2" />
                <Tab label="Questões" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <RevisionsBook />
            </TabPanel>
            <TabPanel value="2">
              <RevisionsInfo />
            </TabPanel>
            <TabPanel value="3">
              <RevisionsQuestions />
            </TabPanel>
            <ConfirmationModal
              message="Você tem certeza que deseja deletar ?"
              onConfirm={""}
              onCancel={handleCancel}
              isModalOpen={isOpen}
            />
          </TabContext>

          <div className="buttons-modal-control">
            <button
              className="btn btn-primary btnhover f-right  p-2"
              onClick={onClose}
            >
              FECHAR
            </button>
          </div>
        </Box>
      </div>
    </>
  );
}

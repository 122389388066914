import React, { useState, useEffect, useContext, useCallback } from "react";
import dayjs from "dayjs";
import AuthContext from "../../../contexts/auth";
import showPopup from "../../PopUp";
import api from "../../../services/config.url";
import InputDateGen from "../../input/inputDateGen";
import InputDate from "../../input/inputDate";
import InputText from "../../input/inputText";

export default function HistoricRevisionsModal({
  data,
  onCancel,
  isModalOpen,
}) {
  const { setLoading } = useContext(AuthContext);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [response, setResponse] = useState([]);
  const [newObservation, setNewObservation] = useState({});

  const getRevisionsHistoric = useCallback(async () => {
    setIsLoadingContainer(true);

    if (!data) return;
    try {
      const response = await api.get(`/customers/revisions-book/${data._id}`);
      setResponse(response.data);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de cadernos do painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      setNewObservation({});
    }
  }, [data]);

  useEffect(() => {
    getRevisionsHistoric();
  }, [getRevisionsHistoric]);

  const deleteHistoricBook = useCallback(
    async (id) => {
      setIsLoadingContainer(true);
      try {
        const response = await api.delete(
          `/customers/revisions-book/historic/${id}`
        );
        setResponse(response.data);
        getRevisionsHistoric();
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao deletar um cadernos do painel";
        showPopup(BackendError || genericError, "error");

        showPopup("Erro ao carregar a tabela de revisões", "error");
      } finally {
        setIsLoadingContainer(false);
        // onCancel();
      }
    },
    [onCancel, getRevisionsHistoric]
  );

  const updateCurrent = async (body, type) => {
    const { id, data } = body;

    let payload = {};

    if (type === "observation") {
      payload.observation = data;
    } else {
      const dates = JSON.parse(data);
      if (dates.oldDate === dates.newDate) return;
      payload.currentDate = dates.newDate;
    }
    try {
      setLoading(true);
      await api.patch(`/customers/revisions-book/historic/${id}`, payload);
      const message = "Registro atualizado com sucesso.";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao editar o histórico";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const createHistoric = async () => {
    const { currentDate, observation } = newObservation;

    if (!currentDate || !observation) {
      return showPopup("Preencha todos os campos", "Alert");
    }
    let payload = {
      ...data,
      type: "REVISION",
      product: data?.product?._id || null,
      parent: data?.parent || null,
      currentDate,
      observation,
    };

    try {
      setIsLoadingContainer(true);
      await api.post(`/customers/revisions-book/historic/${data._id}`, payload);
      const message = "Registro criado com sucesso.";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao criar o histórico do caderno no painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      getRevisionsHistoric();
      handleCancel();
    }
  };

  const handleNewDate = (date) => {
    setNewObservation({ ...newObservation, currentDate: date });
  };

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <>
      {isModalOpen && (
        <div className="container-modal-books">
          <div className="bg-modal">
            <div className="create-modal-layout">
              <div className="historic-topbar">
                <p className="m-0">
                  {data.sku} - {data.name}
                </p>
                <button
                  className="text-center btn btn-primary btnhover p-1"
                  onClick={handleCancel}
                >
                  Fechar
                </button>
              </div>
              <div className="box-itens-revisions">
                <>
                  {isLoadingContainer ? (
                    <div
                      style={{ margin: "20px auto" }}
                      className="loadingContainer"
                    ></div>
                  ) : (
                    <>
                      {response.length > 0 ? (
                        <>
                          {response.map((item, index) => (
                            <React.Fragment key={index}>
                              <div key={index} className="container-revision">
                                <div className="info-container-result">
                                  {/* <p className="info-container-date">
                                    <div
                                      onClick={() => setActiveObsBook(index)}
                                    >
                                      {dayjs(item.currentDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  </p> */}
                                  <div className="m-l10">
                                    <InputDate
                                      item={item}
                                      updateCurrent={updateCurrent}
                                    />
                                  </div>
                                  <InputText
                                    item={item}
                                    updateCurrent={updateCurrent}
                                  />
                                </div>
                                <button
                                  className="btnCad deleteBtnCad btnhover"
                                  onClick={() => deleteHistoricBook(item._id)}
                                >
                                  X
                                </button>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          <p className="text-center">
                            Nenhum histórico encontrado
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
                <div className="container-revision">
                  <div className="info-container-result">
                    {/* <p className="info-container-date">
                      <div>{dayjs().format("DD/MM/YYYY")}</div>
                    </p> */}
                    <div className="m-l10">
                      <InputDateGen
                        value={newObservation.currentDate}
                        onChange={handleNewDate}
                        placeholder="Preencha..."
                        mask="99/99/9999"
                      />
                    </div>

                    <input
                      className="noBreakLine"
                      placeholder={"Observações..."}
                      defaultValue={""}
                      onChange={(e) =>
                        setNewObservation({
                          ...newObservation,
                          observation: e.target.value,
                        })
                      }
                    />
                  </div>
                  <button
                    className="btnCad btn btn-primary btnhover"
                    onClick={() => createHistoric()}
                  >
                    Criar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useContext, useCallback, useEffect } from "react";
import AuthContext from "../../contexts/auth";
import showPopup from "../PopUp";
import { useForm } from "react-hook-form";
import api from "../../services/config.url";

export default function ModalSpots({
  open,
  onClose,
  internalSpots,
  punctuation,
  onUpdateTable,
}) {
  // const { setLoading } = useContext(AuthContext);
  const [panel, setPanel] = useState(internalSpots);
  const { register, handleSubmit, reset } = useForm();
  const [studyDaysSpots, setStudyDaysSpots] = useState(
    punctuation?.studyDaysSpots || {}
  );

  useEffect(() => {
    if (panel && panel.nameOfWeek !== internalSpots.nameOfWeek) {
      reset();
      setPanel(internalSpots);
    }
  }, [open]);

  useEffect(() => {
    setStudyDaysSpots(punctuation.studyDaysSpots);
  }, [open]);

  const handleBtn = (type) => {
    if (studyDaysSpots && studyDaysSpots.hasOwnProperty(type)) {
      const current = studyDaysSpots[type];
      const newCurrent = !current;
      setStudyDaysSpots({ ...studyDaysSpots, [type]: newCurrent });
    }
  };

  const closeModal = useCallback(() => {
    setPanel([]);
    onClose();
  }, [onClose]);

  const updateInternalSpots = async (data) => {
    const PanelParams = {
      nameOfWeek: panel.nameOfWeek,
      cad: Number(data.cad || 0) || panel.panelCad,
      info: Number(data.info || 0) || panel.panelInfo,
      qc: Number(data.qc || 0) || panel.panelQc,
    };

    try {
      // setLoading(true);
      await api.patch(`/customers/punctuation/${punctuation._id}`, {
        internalSpots: PanelParams,
      });
      // setPanel(response?.data?.internalSpots);
      showPopup("Painel atualizado com sucesso.", "success");
      // onUpdateTable(response?.data); // Chama a função para atualizar a tabela com os novos dados
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao atualizar suas pontuações";
      showPopup(BackendError || genericError, "error");
    } finally {
      reset();
      // setLoading(false);
      closeModal(); // Fecha o modal após a atualização
    }
  };
  if (open) {
    document.body.classList.add("disable-scroll");
  }
  if (!open) {
    document.body.classList.remove("disable-scroll");
    return null;
  }

  return (
    <>
      <div>
        <div className="backgroundDark" onClick={onClose}></div>
        <form onSubmit={handleSubmit(updateInternalSpots)}>
          <div className="box-modal p-3">
            <button
              className="btn btn-primary btnhover f-right p-2"
              onClick={onClose}
              style={{ height: "30px", width: "30px" }}
            >
              X
            </button>
            <div>
              <div className="modal-bloco">
                <p className="text-bold">
                  Meta de PONTOS para CAD, INFO e QC:{" "}
                </p>
              </div>
              <div className="modal-bloco">
                <p className="text-bold">Cadernos (CAD): </p>
                <input
                  className="text-bold"
                  defaultValue={panel.panelCad}
                  maxLength="5"
                  placeholder="0"
                  style={{ border: "1px solid #cecece", fontWeight: "300" }}
                  {...register("cad")}
                />
              </div>
              <div className="modal-bloco">
                <p className="text-bold">Informativos (INFO): </p>
                <input
                  className="text-bold"
                  defaultValue={panel.panelInfo}
                  maxLength="5"
                  placeholder="0"
                  style={{ border: "1px solid #cecece", fontWeight: "300" }}
                  {...register("info")}
                />
              </div>
              <div className="  modal-bloco">
                <p className="text-bold">Questões (QC): </p>
                <input
                  className="text-bold"
                  defaultValue={panel.panelQc}
                  maxLength="5"
                  placeholder="0"
                  style={{ border: "1px solid #cecece", fontWeight: "300" }}
                  {...register("qc")}
                />
              </div>
            </div>
            <div className="studyDays">
              <p
                className={
                  panel.nameOfWeek === "segunda-feira" ? "checked" : ""
                }
                onClick={() => handleBtn("monday")}
              >
                SEG
              </p>
              <p
                className={panel.nameOfWeek === "terça-feira" ? "checked" : ""}
                onClick={() => handleBtn("tuesday")}
              >
                TER
              </p>
              <p
                className={panel.nameOfWeek === "quarta-feira" ? "checked" : ""}
                onClick={() => handleBtn("wednesday")}
              >
                QUA
              </p>
              <p
                className={panel.nameOfWeek === "quinta-feira" ? "checked" : ""}
                onClick={() => handleBtn("thursday")}
              >
                QUI
              </p>
              <p
                className={panel.nameOfWeek === "sexta-feira" ? "checked" : ""}
                onClick={() => handleBtn("friday")}
              >
                SEX
              </p>
              <p
                className={panel.nameOfWeek === "sábado" ? "checked" : ""}
                onClick={() => handleBtn("saturday")}
              >
                SÁB
              </p>
              <p
                className={panel.nameOfWeek === "domingo" ? "checked" : ""}
                onClick={() => handleBtn("sunday")}
              >
                DOM
              </p>
            </div>
            <div className=" m-t10 message-spots">
              <p className="text-bold m-0">
                Obs: a média dos estudantes em geral é de 10 pontos por hora.
                Estipule uma meta de pontos viável, conforme suas condições
                pessoais e tempo disponível.{" "}
              </p>
            </div>
            <div className="buttons-modal">
              <button
                className="btn btn-primary btnhover f-right m-t50 "
                type="submit"
              >
                SALVAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

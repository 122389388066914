import React, { useContext, useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
//Components
import PageTitle from "./../layouts/PageTitle";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import api from "../services/config.url";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { List, Divider } from "antd";
import ArrowDown from "../assets/images/arrow-down.png";
import { FormattedPrice } from "../components/FormatValues";
import ModalPdf from "../components/modal/modalPdf";
import CarouselHeadline from "../components/CarouselHeadline";

function Headlines() {
  const [currentPage, setCurrentPage] = useState(1);
  const { user, setCart, cart, setSizeCart, setLoading } =
    useContext(AuthContext);
  const [response, setResponse] = useState([]);
  const [productsHeadline, setProductsHeadline] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [pdf, setPdf] = useState("");
  const [query, setQuery] = useState({
    current: currentPage,
    pageSize: 10,
  });

  useEffect(() => {
    const formattedQuery = new URLSearchParams({
      current: currentPage,
      pageSize: 500,
    }).toString();

    setLoading(true);

    api
      .get(`/customers/headlines/products?${formattedQuery}`)
      .then((response) => {
        setProductsHeadline(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível listar as Manchetes";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    const formattedQuery = new URLSearchParams(query).toString();
    setLoading(true);
    api
      .get(`/customers/headlines/all?${formattedQuery}`)
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível listar as Manchetes";
        showPopup(BackendError || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  const handlePdf = (pdf) => {
    setPdf(pdf);
    setIsOpen(true);
  };

  const addCart = async (data) => {
    if (!user) {
      showPopup(
        "Acesse sua conta para adicionar produtos ao carrinho",
        "alert"
      );
      return navegate.push("/acesso");
    }

    const cartId = cart._id;
    setLoading(true);
    try {
      const response = await api.post(`/customers/cart/${cartId}/item`, {
        product: data._id,
        amountUnit: data.amount,
        sku: data.sku,
        name: data.name,
        materia: data.materia,
        description: data.description,
      });
      setSizeCart(response.data.items.length);
      setCart(response.data);
      showPopup("Produto adicionado ao carrinho", "success");
    } catch (error) {
      if (error?.response?.data?.message === "Unauthorized") {
        showPopup("Faça login novamente", "error");
        return navegate.push("/acesso");
      }
      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };

  const ProductList = (props) => {
    const { products } = props;
    if (products) {
      return (
        <div>
          <div>
            <div className="img-product-headline">
              <img
                src={products && products?.photos[0].photo?.url}
                alt={"Imagem"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "95%",
            backgroundColor: "#f3f3f3",
          }}
        >
          Sem produto
        </div>
      );
    }
  };

  const HeadlineList = () => {
    if (!response.records || response.records.length === 0) {
      return (
        <div className="emptyCart">
          <div>vazio</div>
        </div>
      );
    }

    return (
      <>
        {response?.records.map((headline, index) => (
          <React.Fragment key={index}>
            <CAccordion>
              <CAccordionItem itemKey={1} className="m-b10">
                <CAccordionHeader>
                  {headline.code} - {headline.title}
                </CAccordionHeader>
                <CAccordionBody style={{ padding: " 10px 10px 10px 10px" }}>
                  <div className="container-top-headline">
                    <div className="">
                      <ProductList products={headline.products} />
                    </div>
                    <div className="right-content-manchete-product">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: headline.description,
                        }}
                      ></div>
                      <div className="manchete-product-actions">
                        {headline.products?.photos[0]?.photo?.url && (
                          <div
                            className="amostra"
                            onClick={() =>
                              handlePdf(
                                headline.products?.downloadSample[0]?.photo
                                  ?.url || ""
                              )
                            }
                          >
                            <p className="m-0 p-1"> Ver amostra </p>
                          </div>
                        )}
                        <h5 className="amount m-t10">
                          <FormattedPrice price={headline.products.amount} />
                        </h5>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <div
                            className="btn btn-primary btnhover btnhover2 "
                            style={{ height: "36px", width: "80" }}
                            onClick={() => addCart(products)}
                          >
                            <i className="flaticon-shopping-cart-1"></i>
                            <span className="m-1">Adicionar no carrinho</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {headline.topics?.length > 0 && (
                    <React.Fragment>
                      <Divider
                        style={{ margin: 0, backgroundColor: "#0061da" }}
                      />
                      <p
                        className="m-2"
                        style={{ color: "#0061da", textAlign: "center" }}
                      >
                        Veja pontos importantes abordados neste caderno:{" "}
                      </p>
                      <Divider
                        style={{ margin: 0, backgroundColor: "#0061da" }}
                      />
                      <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={headline.topics.map((x) => {
                          return {
                            href: x.url,
                            title: x.title,
                            avatar: x.code,
                            content: x.description,
                          };
                        })}
                        renderItem={(item) => (
                          <List.Item key={item.title}>
                            <List.Item.Meta
                              title={
                                <p>
                                  {item.avatar} - {item.title}
                                </p>
                              }
                            />
                            <div className="items-headline">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                              <iframe
                                height="auto"
                                src={item.href}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              ></iframe>
                            </div>
                          </List.Item>
                        )}
                      />
                    </React.Fragment>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className="header-padding-main">
      <div className="page-content bg-white">
        <PageTitle parentPage="CJ" childPage="Saúde" />

        <ModalPdf
          pdf={pdf}
          isModalOpen={isOpen}
          isModalClose={() => setIsOpen(false)}
        />
        <section className="content-inner-2 m-b30 testimonial-wrapper">
          <CarouselHeadline items={productsHeadline} />
        </section>

        <section className="container m-t100 box-about-us">
          <div className="col-lg-12 m-b100">
            <HeadlineList />
          </div>
          <div className="pagination-component">
            <Stack spacing={2}>
              <Pagination
                count={response.pages}
                page={currentPage}
                onChange={(event, page) => handlePagination(page)}
              />
            </Stack>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Headlines;

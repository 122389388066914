import React, { useEffect, useState, useContext, memo } from "react";
import { Link } from "react-router-dom";
import api from "../services/config.url";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Component
import NewsLetter from "../components/NewsLetter";
import PageTitle from "./../layouts/PageTitle";

function BooksGridView() {
  const [products, setProducts] = useState([]);
  const { setLoading } = useContext(AuthContext);
  const [pageSize, setPageSize] = useState(1000);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/customers/products?current=1&pageSize=${pageSize}`)
      .then((response) => {
        setProducts(response.data.records);
      })
      .catch((error) => {
        let genericError = "Erro ao filtrar os produtos";
        showPopup(error?.response?.data?.message || genericError, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize]);

  const showMore = () => {
    if (pageSize > products.length + 12) {
      showPopup("Não há mais produtos para carregar", "error");
    }
    setPageSize(pageSize + 12);
  };
  const RenderProducts = () => {
    if (!products) return null;

    return (
      <>
        {products
          .filter((x) => x.amount !== 0 && x.parent === null)
          .map((data, index) => (
            <Accordion key={index} className="m-b10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="headerColapse">
                  <strong className="p-l10">
                    <span>
                      {data.sku} {"-"}
                    </span>{" "}
                    {data.name}
                  </strong>
                  <Link to={`/livro-detalhes/${data.slug}`}>
                    <button className="m-r20 btn btn-primary btnhover btn-details">
                      Ver detalhes
                    </button>
                  </Link>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {products.filter((x) => x.parent === data._id).length ===
                  0 ? (
                    <> Caderno único </>
                  ) : (
                    <>
                      {products
                        .filter((x) => x.parent === data._id)
                        .map((book, index) => (
                          <section
                            key={index}
                            className="row container-books-list"
                          >
                            <div>
                              <p className="m-0">
                                {book.sku} - {book.name}
                              </p>
                            </div>
                          </section>
                        ))}
                    </>
                  )}
                </>
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  };

  return (
    <div className="header-padding-main">
      <div className="page-content bg-grey">
        <PageTitle parentPage="CJ" childPage="Lista de Cadernos" />

        <div className="content-inner-1 border-bottom">
          <div className="container">
            <div className="row ">
              <div className="col-xl-12">
                <RenderProducts />
              </div>
            </div>
          </div>
          {pageSize <= products.length && (
            <section className="container box-main">
              <div className="btn-book">
                <button onClick={showMore}>Carregar mais</button>
              </div>
            </section>
          )}
        </div>
        <NewsLetter />
      </div>
    </div>
  );
}
export default BooksGridView;

import React, { useContext, useState } from "react";
import AuthContext from "../../contexts/auth";
import showPopup from "../PopUp";
import ConfirmationModal from "./modalConfirmation";
import api from "../../services/config.url";

export default function ModalActions({ open, onClose, calendar }) {
  const { setLoading } = useContext(AuthContext);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState("");
  const [messageDelete, setMessageDelete] = useState("");

  const clearScore = async (type) => {
    try {
      setLoading(true);
      await api.put(`/customers/punctuation/clear/${calendar}?type=${type}`);

      const types = [
        { name: "CAD", message: "Seus registros de CAD foram deletados" },
        { name: "INFO", message: "Seus registros de INFO foram deletados" },
        { name: "QC", message: "Seus registros de QC foram deletados" },
        { name: "ALL", message: "Sua pontuação foi resetada" },
        { name: "HISTORIC", message: "Seu histórico foi resetado" },
        { name: "GOALS", message: "Seu painel de metas foi resetado" },
      ];

      const message = types.filter((typeMessage) => typeMessage.name === type);
      showPopup(message[0]?.message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao limpar o painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      if (type === "CAD") {
        await api.get(`/customers/revisions-book/check-revisions`);
      } else if (type === "QC") {
        await api.get(`/customers/revisions-questions/check`);
      }
      setLoading(false);
      onClose(true);
    }
  };

  const handleCancel = () => {
    setIsOpenConfirmation(false);
  };

  const handleDelete = () => {
    clearScore(typeToDelete);
    setIsOpenConfirmation(false);
  };

  const handleDeleteFunction = (value) => {
    setIsOpenConfirmation(true);
    setTypeToDelete(value);

    if (value === "CAD") {
      setMessageDelete(
        `Apagar todas as datas, históricos e registros dentro de CAD ?`
      );
    }
    if (value === "INFO") {
      setMessageDelete(`Apagar todos informativos criados em INFO?`);
    }
    if (value === "QC") {
      setMessageDelete(`Apagar todos as questões criadas em QC ?`);
    }
    if (value === "ALL") {
      setMessageDelete(`Apagar todos as PONTUAÇÕES DA SEMANA ?`);
    }
    if (value === "HISTORIC") {
      setMessageDelete(`Apagar todo o HISTÓRICO SEMANAL ?`);
    }
    if (value === "GOALS") {
      setMessageDelete(`Apagar todas as METAS DA SEMANA ?`);
    }
  };

  if (!open) return null;
  if (open) {
    document.body.style.overflow = "hidden";
  }
  if (!open) {
    document.body.style.overflow = "scroll";
    return null;
  }
  return (
    <>
      <div className="backgroundDark" onClick={onClose}></div>
      <div className="box-actions">
        <button
          className="btn btn-primary btnhover f-right p-2 m-r10 m-t10"
          onClick={onClose}
          style={{ height: "30px", width: "30px" }}
        >
          X
        </button>
        <div className="p-3">
          <h5>Ações</h5>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("CAD")}
            >
              <span>
                Limpar todos os <strong> registros de CAD </strong>
              </span>
            </button>
          </div>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("INFO")}
            >
              <span>
                Limpar todos os <strong> registros de INFO </strong>
              </span>
            </button>
          </div>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("QC")}
            >
              <span>
                Limpar todos os <strong> registros de QC </strong>
              </span>
            </button>
          </div>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("ALL")}
            >
              <span>
                Limpar toda a <strong> pontuação da semana </strong>
              </span>
            </button>
          </div>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("HISTORIC")}
            >
              <span>
                Limpar todos os <strong> históricos semanais </strong>
              </span>
            </button>
          </div>
          <div className="action">
            <button
              className="btn btn-primary btnhover m-0"
              onClick={() => handleDeleteFunction("GOALS")}
            >
              <span>
                Limpar todos as <strong> metas da semana </strong>
              </span>
            </button>
          </div>
        </div>
        <ConfirmationModal
          message={messageDelete}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          isModalOpen={isOpenConfirmation}
        />
      </div>
    </>
  );
}

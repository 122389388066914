import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import api from "../../services/config.url";

SwiperCore.use([Navigation, Pagination, Autoplay]);

function TestimonialSlider() {
  const swiperRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  const [isLoop, setIsLoop] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 1000,
  });
  const encodedQuery = Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width > 1200 && testimonials.length > 2) {
      setIsLoop(true);
    } else if (windowDimensions.width < 1200 && testimonials.length > 1) {
      setIsLoop(true);
    } else {
      setIsLoop(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    api
      .get(`/customers/testimonials?${encodedQuery}`)
      .then((response) => {
        setTestimonials(response.data.records);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    if (!swiperRef || !swiperRef?.current) return;

    const swiperInstance = swiperRef?.current?.swiper;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          swiperInstance.autoplay.start();
        } else {
          swiperInstance.autoplay.stop();
        }
      },
      { threshold: 0.5 } // Ajuste o valor do threshold conforme necessário
    );

    observer.observe(swiperInstance.el);

    // return () => {
    //   observer.unobserve(swiperInstance.el);
    // };
  }, []);

  const handleMouseEnter = () => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.start();
    }
  };

  const TestemunialContent = () => {
    return (
      <>
        {testimonials && testimonials.length > 0 && (
          <>
            <div className="container">
              <div className="testimonial">
                <div className="section-head book-align">
                  <div>
                    <h2 className="title mb-0 m-r50">Depoimentos</h2>
                  </div>
                  <div className="pagination-align style-1">
                    <div className="testimonial-button-prev swiper-button-prev">
                      <i className="fa-solid fa-angle-left"></i>
                    </div>
                    <div className="testimonial-button-next swiper-button-next">
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="testimonial-slider-container p-l20 p-r20"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Swiper
                ref={swiperRef}
                slidesPerView={3}
                spaceBetween={30}
                loop={isLoop}
                navigation={{
                  prevEl: ".testimonial-button-prev",
                  nextEl: ".testimonial-button-next",
                }}
                autoplay={{ delay: 4000 }}
                initialSlide={2}
                breakpoints={{
                  360: {
                    slidesPerView: 1,
                  },
                  600: {
                    slidesPerView: 1,
                  },
                  767: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {testimonials.map((item, ind) => (
                  <SwiperSlide key={ind}>
                    <div className="testimonial-1">
                      <div className="testimonial-info">
                        <div className="testimonial-text">
                          <p>{item.description}</p>
                        </div>
                        <div className="testimonial-detail">
                          <div className="testimonial-pic">
                            <img src={item?.photos[0]?.photo?.url} alt="" />
                          </div>
                          <div className="info-right">
                            <h6 className="testimonial-name">{item.author}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </>
    );
  };
  return <TestemunialContent />;
}

export default TestimonialSlider;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import showPopup from "../../PopUp";
import api from "../../../services/config.url";
import ConfirmationModal from "../modalConfirmation";

export default function CaccordionHeader({ item, info, refetch }) {
  const { register, handleSubmit } = useForm();
  const [edditing, setEdditing] = useState(false);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState();
  const [messageDelete, setMessageDelete] = useState("");
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const updateInfo = async (data) => {
    const { from, to } = data;

    if (!from) {
      return showPopup("O campo dê é óbrigatório", "alert");
    }

    if (
      (Number(from) > Number(to) || Number(from) === Number(to)) &&
      Number(to)
    ) {
      return showPopup(
        "O valor 'Dê' deve ser maior que o valor de 'Até'",
        "alert"
      );
    }
    let payload = {
      ...item,
      from: Number(from),
      to: Number(to),
    };

    try {
      setIsLoadingContainer(true);
      await api.patch(`/customers/revisions-info/${item._id}`, payload);
      showPopup("Informativo atualizado", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro na atualização de informativos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setEdditing(false);
      setIsLoadingContainer(false);
    }
  };

  const handleInputValue = (field, value) => {
    const { court, from, to } = item;
    const newItem = { ...item, [field]: value };
    if (
      court === newItem.court &&
      from === Number(newItem.from) &&
      to === Number(newItem.to)
    ) {
      setEdditing(false);
    } else {
      setEdditing(true);
    }
  };

  const handleDeleteFunction = (value) => {
    setIsOpenConfirmation(true);
    setTypeToDelete(value._id);
    setMessageDelete(`Deseja deletar o informativo ?`);
  };

  const handleDelete = () => {
    if (!typeToDelete) return;
    deleteInfo(typeToDelete);
    setIsOpenConfirmation(false);
  };

  const handleClose = () => {
    setIsOpenConfirmation(false);
  };

  const deleteInfo = async (id) => {
    try {
      setIsLoadingContainer(true);
      await api.delete(`/customers/revisions-info/${id}`);
      showPopup("Informativo deletado com sucesso", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Ocorreu um erro ao deletar esta revisão";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsLoadingContainer(false);
      refetch();
    }
  };

  return (
    <>
      <React.Fragment>
        <ConfirmationModal
          message={messageDelete}
          onConfirm={handleDelete}
          onCancel={handleClose}
          isModalOpen={isOpenConfirmation}
        />
        <form
          onSubmit={handleSubmit(updateInfo)}
          className="form-info form-layout-court"
        >
          <div className="info-header header-accordion-info p-0">
            <div>
              <input
                type="number"
                placeholder={"de..."}
                min="0"
                autoComplete="off"
                defaultValue={item?.from}
                onInput={(e) => handleInputValue("from", e.target.value)}
                name={"from"}
                maxLength="5"
                {...register("from")}
                style={{ marginLeft: "4px" }}
              />

              <div
                style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                {" "}
                a{" "}
              </div>

              <input
                type="number"
                placeholder={"até..."}
                min="0"
                defaultValue={item?.to}
                autoComplete="off"
                name={"to"}
                maxLength="5"
                {...register("to")}
                onInput={(e) => handleInputValue("to", e.target.value)}
              />
            </div>
            <div>
              {edditing && (
                <button
                  className="btn btn-primary btnhover btn-save-info"
                  style={{
                    width: "50px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                >
                  OK
                </button>
              )}

              <button
                style={{
                  width: "36px",
                  height: "33px",
                  margin: "5px",
                }}
                className="btnCad deleteBtnCad btnhover"
                onClick={() => handleDeleteFunction(item)}
              >
                X
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    </>
  );
}

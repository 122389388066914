import React from "react";

export function FormattedPrice(props) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return <span>{formatter.format(props.price)}</span>;
}

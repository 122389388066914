import React, { useContext } from "react";
import "swiper/css";
import logo from "./../../assets/images/logo2.png";
import styled from "styled-components";
import GlobalContext from "../../contexts/global";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HomeMainSlider() {
  const global = useContext(GlobalContext);

  const ContentInfo = styled.div`
    position: relative;
    &::after {
      content: "";
      background: url(${(global &&
        global?.global?.bannerConfig?.desktopBanner) ||
      ""});
      position: absolute;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-size: cover;
      opacity: ${(global && global?.global?.bannerConfig?.opacity) || "0.5"};
      @media (max-width: 768px) {
        background: url(${(global &&
          global?.global?.bannerConfig?.mobileBanner) ||
        ""});
        &::after {
          background: url(${(global &&
            global?.global?.bannerConfig?.mobileBanner) ||
          ""});
        }
      }
    }
  `;

  return (
    <>
      <div className="banner-content p-0 ">
        <div className="content-text" style={{ position: "relative" }}>
          <div className="cursive-footer-banner"></div>

          <ContentInfo>
            <LazyLoadImage className="content-info" />
            <div className="content-info">
              <img src={logo} />
              <h2 className="title" data-swiper-parallax="-20">
                CADERNOS REALMENTE
                <strong> COMPLETOS</strong> PARA CONCURSOS
              </h2>

              <p className="pt-3 banner-text-blue">
                As principais matérias do Direito
                <strong> estão aqui!</strong>
              </p>
              <p className="banner-description">
                Conteúdo selecionado a dedo, com tudo o que você precisa para
                ser aprovado(a): leis comentadas, doutrina essencial, questões
                de concurso e jurisprudência atualizada!
              </p>
              <p className="pt-4">
                Os <strong>4 pilares da aprovação</strong>, em um só lugar!
              </p>
            </div>
          </ContentInfo>
        </div>
      </div>
    </>
  );
}

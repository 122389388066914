import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import api from "../services/config.url";
import PageTitle from "./../layouts/PageTitle";

const Collaborations = () => {
  const [response, setResponse] = useState([]);
  const { setLoading } = useContext(AuthContext);

  const getAboutUs = async () => {
    setLoading(true);
    try {
      const response = await api.get("customers/global/collaborators");
      setResponse(response.data);
    } catch (error) {
      const genericError = "Erro ao carregar a página (Colaboradores) ";
      return showPopup(genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAboutUs();
  }, []);

  return (
    <div className="header-padding-main">
      <PageTitle parentPage="CJ" childPage="Colaboradores" />
      <section className="container-collaboration m-t50 mb-50">
        <div id="targetElement" className="bottom-collaboration">
          <div dangerouslySetInnerHTML={{ __html: response }}></div>
        </div>
      </section>
    </div>
  );
};
export default Collaborations;

import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import PageTitle from "./../layouts/PageTitle";
import NewsLetter from "../components/NewsLetter";
import api from "../services/config.url";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";

const ContactUs = () => {
  const { register, handleSubmit, reset } = useForm();
  const { setLoading } = useContext(AuthContext);
  const [isDisabled, setDisabled] = useState(false);
  const [typeSubject, setTypeSubject] = useState("");
  const [images, setImages] = useState([]);

  const onAddImage = () => {
    if (images.length >= 5) {
      return showPopup("Você só pode anexar 5 imagens.", "alert");
    }
    setImages([...images, null]);
  };

  const onRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleNumber = (value) => {
    setTypeSubject(value);
    if (value !== "Sugestões de alterações de Cadernos") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const sendEmail = async (data) => {
    try {
      const number = data.phone.replace(/[^\d]/g, "");
      if (number.length < 11) {
        showPopup("Número de celular inválido", "error");
        return;
      }

      setLoading(true);

      // CADASTRA FORMULARIO DE CONTATO E RETORNA O ID
      const response = await api.post(`/customers/message/contact`, {
        ...data,
        typeSubject,
      });

      if (response.data._id) {
        const _id = response.data._id;

        // CADASTRA IMAGENS USANDO O ID RETORNADO
        const formData = new FormData();
        for (let image = 0; image < images.length; image++) {
          formData.append("file", images[image]);
        }

        try {
          await api.post(`/customers/files/contact/form/${_id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          showPopup("Enviado com sucesso", "success");
          reset();
        } catch (error) {
          const BackendError = error?.response?.data?.message;
          const genericError = "Ocorreu um erro ao salvar as imagens.";
          showPopup(BackendError || genericError, "error");
        }
      } else {
        const genericError = "Não foi possível salvar as imagens.";
        showPopup(genericError, "error");
      }
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Não foi possível enviar o formulário";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="header-padding-main">
      <div className="page-content">
        <PageTitle parentPage="CJ" childPage="Fale conosco" />
        <section className="contact-wraper1">
          <div className="container-fluid p-0">
            <div className="row justify-content-center mt-4">
              {/* FORM */}
              <div className="col-10 col-lg-10 m-b80 m-t80 p-0">
                <div className="contact-area1 m-md-r0 p-0">
                  <form
                    className="dz-form dzForm"
                    onSubmit={handleSubmit(sendEmail)}
                  >
                    <input
                      type="hidden"
                      className="form-control"
                      name="dzToDo"
                      defaultValue="Contact"
                    />
                    <div className="dzFormMsg"></div>
                    <div className="input-group input-duble-first">
                      <div>
                        <label>Nome:</label>
                        <input
                          required
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          name="dzName"
                          {...register("name")}
                        />
                      </div>
                      <div>
                        <label>Telefone:</label>
                        <InputMask
                          mask="(99) 99999-9999"
                          required
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          name="dzName"
                          {...register("phone")}
                        />
                      </div>
                      <div>
                        <label>E-mail:</label>
                        <input
                          required
                          type="email"
                          autoComplete="off"
                          className="form-control"
                          name="dzEmail"
                          {...register("email")}
                        />
                      </div>
                    </div>
                    <div className="input-group input-duble-second">
                      <div>
                        <label>Assunto:</label>
                        <select
                          onChange={(e) => handleNumber(e.target.value)}
                          required
                          type="text"
                          className="form-control"
                          name="dzEmail"
                        >
                          <option> Selecionar assunto </option>
                          <option value="Depoimentos e Elogios">
                            Depoimentos e Elogios
                          </option>
                          <option value="Sugestões de alterações de Cadernos">
                            Sugestões de alterações de Cadernos
                          </option>
                          <option value="Assuntos Gerais">
                            Assuntos Gerais
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>N° do caderno:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="dzEmail"
                          autoComplete="off"
                          {...register("bookNumber")}
                          disabled={!isDisabled}
                        />
                      </div>
                    </div>
                    <label>Mensagem:</label>
                    <div className="input-group">
                      <textarea
                        required
                        name="dzMessage"
                        rows="5"
                        autoComplete="off"
                        className="form-control"
                        {...register("description")}
                      ></textarea>
                    </div>
                    {images.map((image, index) => (
                      <div key={index}>
                        <input
                          type="file"
                          className="btn-ale btn mb-2"
                          name={`image-${index}`}
                          {...register(`images.${index}`)}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const newImages = [...images];
                            newImages[index] = file;
                            setImages(newImages);
                          }}
                        />
                        <button
                          type="button"
                          className="m-3 ti-close p-2 removeImageContact"
                          onClick={() => onRemoveImage(index)}
                        ></button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={onAddImage}
                      className="mb-5 mt-2 p-2 btn-ale btn"
                    >
                      Adicionar imagem
                    </button>

                    <div className="input-group">
                      <button
                        name="submit"
                        type="submit"
                        value="ENVIAR MENSAGEM"
                        className="btn w-100 btn-ale"
                      >
                        ENVIAR MENSAGEM
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 banner-whats">
                <div className="col-11 limit-of-text">
                  <h2>Participe de nosso grupo de Revisão do Whatsapp!</h2>
                  <p>
                    Compartilhamos <strong>questões</strong> de prova
                    selecionadas a dedo, notícias sobre concursos e cupons de{" "}
                    <strong> descontos!</strong>
                  </p>
                  <h2>
                    <a href="https://chat.whatsapp.com/FdSrgSa0QA4HHaQIlB2gw8">
                      {" "}
                      Clique aqui e ingresse no Grupo de Revisão!
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <NewsLetter />
      </div>
    </div>
  );
};
export default ContactUs;

import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import PageTitle from "./../layouts/PageTitle";
import NewsLetter from "../components/NewsLetter";
import api from "../services/config.url";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";
import { useParams } from "react-router-dom";

const Sugestion = () => {
  const { setLoading, signIn, isAuthenticated } = useContext(AuthContext);
  const { register, handleSubmit, reset } = useForm();
  const [isDisabled, setDisabled] = useState(false);
  const { sku } = useParams();
  const [loginData, setLoginData] = useState({});
  const [images, setImages] = useState([]);

  const onAddImage = () => {
    if (images.length >= 5) {
      return showPopup("Você só pode anexar 5 imagens.", "alert");
    }
    setImages([...images, null]);
  };

  const onRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const sendEmail = (data) => {
    if (!data.sugestion) {
      return showPopup("Campo de sugestão é obrigatório", "alert");
    }
    const body = {
      ...data,
      sku: sku,
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.post(`/customers/message/feedbacks`, {
          ...body,
        });
        showPopup("Enviado com sucesso", "success");
        reset();

        if (response.data._id) {
          const _id = response.data._id;

          // CADASTRA IMAGENS USANDO O ID RETORNADO
          const formData = new FormData();
          for (let image = 0; image < images.length; image++) {
            formData.append("file", images[image]);
          }

          try {
            await api.post(`/customers/files/feedback/form/${_id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            showPopup("Enviado com sucesso", "success");
            reset();
          } catch (error) {
            const BackendError = error?.response?.data?.message;
            const genericError = "Ocorreu um erro ao salvar as imagens.";
            showPopup(BackendError || genericError, "error");
          }
        } else {
          const genericError = "Não foi possível salvar as imagens.";
          showPopup(genericError, "error");
        }
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível enviar a mensagem";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const login = async (loginData) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    const email = loginData.login.match(emailRegex)
      ? loginData.login
      : undefined;
    const nickname = email ? undefined : loginData.login;
    const payload = { ...loginData, email, nickname };
    await signIn(payload, { redirection: false });
  };

  return (
    <div className="header-padding-main">
      <div className="page-content">
        <PageTitle
          parentPage="CJ"
          childPage="Sugestões de alterações"
          className="m-b20"
        />
        <section className="contact-wraper1 m-t20">
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              {/* FORM */}
              <div className="col-10 col-lg-10 m-b80 m-t80 p-0">
                <div className="contact-area1 m-md-r0 p-0">
                  {/* LOGIN */}
                  {!isAuthenticated && (
                    <div className="w-50">
                      <div className="flex-1">
                        <label>Login:</label>
                        <input
                          required
                          type="email"
                          autoComplete="off"
                          className="form-control"
                          onChange={(e) =>
                            setLoginData({
                              ...loginData,
                              login: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <label>Senha:</label>
                        <InputMask
                          autoComplete="off"
                          type="password"
                          className="form-control"
                          onChange={(e) =>
                            setLoginData({
                              ...loginData,
                              password: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <button
                          name="submit"
                          type="submit"
                          value="ENVIAR MENSAGEM"
                          className="btn w-100 btn-ale"
                          onClick={() => login(loginData)}
                        >
                          Entrar
                        </button>
                      </div>
                      <spam className="spam-sugestion">
                        Faça login para enviar uma sugestão
                      </spam>
                    </div>
                  )}

                  {/* FIM LOGIN */}
                  <form
                    className="dz-form dzForm"
                    onSubmit={handleSubmit(sendEmail)}
                  >
                    <input
                      type="hidden"
                      className="form-control"
                      name="dzToDo"
                      defaultValue="Contact"
                    />
                    <div className="dzFormMsg"></div>
                    {/* <div className="input-group sugestion-three-column">
                      <div>
                        <label>Nome:</label>
                        <input
                          required
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          disabled={!isAuthenticated}
                          name="dzName"
                          {...register("name")}
                        />
                      </div>
                      <div>
                        <label>Telefone:</label>
                        <InputMask
                          mask="(99) 99999-9999"
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          disabled={!isAuthenticated}
                          name="dzName"
                          {...register("phone")}
                        />
                      </div>
                      <div>
                        <label>E-mail:</label>
                        <input
                          type="text"
                          autoComplete="off"
                          disabled={!isAuthenticated}
                          className="form-control"
                          name="dzEmail"
                          {...register("email")}
                        />
                      </div>
                    </div> */}
                    <div className="input-group sugestion-three-column">
                      <div>
                        <label>N° do caderno:</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          value={sku}
                          disabled={!isDisabled}
                        />
                      </div>
                      <div>
                        <label>N° da página:</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          {...register("pageNumber")}
                          disabled={!isAuthenticated}
                        />
                      </div>
                      <div>
                        <label>N° do parágrafo:</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          {...register("paragraphNumber")}
                          disabled={!isAuthenticated}
                        />
                      </div>
                    </div>
                    <label>Sugestões:</label>
                    <div className="input-group">
                      <textarea
                        required
                        name="dzMessage"
                        rows="5"
                        autoComplete="off"
                        className="form-control"
                        disabled={!isAuthenticated}
                        {...register("sugestion")}
                      ></textarea>
                    </div>
                    {images.map((image, index) => (
                      <div key={index}>
                        <input
                          type="file"
                          className="btn-ale btn mb-2"
                          name={`image-${index}`}
                          {...register(`images.${index}`)}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const newImages = [...images];
                            newImages[index] = file;
                            setImages(newImages);
                          }}
                        />
                        <button
                          type="button"
                          className="m-3 ti-close p-2 removeImageContact"
                          onClick={() => onRemoveImage(index)}
                        ></button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={onAddImage}
                      className="mb-5 mt-2 p-2 btn-ale btn"
                    >
                      Adicionar imagem
                    </button>

                    <div>
                      <button
                        name="submit"
                        type="submit"
                        value="ENVIAR MENSAGEM"
                        className="btn w-100 btn-ale"
                        disabled={!isAuthenticated}
                      >
                        ENVIAR MENSAGEM
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 banner-whats">
                <div className="col-11 limit-of-text">
                  <h2>Participe de nosso grupo de Revisão do Whatsapp!</h2>
                  <p>
                    Temos um grupo exclusivo para compartilhamento de conteúdo
                    gratuito e relevante para provas, das principais matérias do
                    direito.
                  </p>
                  <h2>
                    <a href="https://chat.whatsapp.com/FdSrgSa0QA4HHaQIlB2gw8">
                      {" "}
                      Clique aqui e ingresse no grupo! Seja bem-vindo(a)!{" "}
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <NewsLetter />
      </div>
    </div>
  );
};
export default Sugestion;

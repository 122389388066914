import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("token");

const api = axios.create({
  // baseURL: "http://localhost:3001/v1",
  baseURL: "https://api.cadernosjuridicos.com.br/v1/",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default api;

import React, { useState, useContext, useCallback } from "react";
import { Popover } from "antd";
import pincel from "../../../assets/images/pincel.png";

export default function PopUpModal(props) {
  const { item, updateCurrent } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onSave = (data) => {
    setIsOpen(false);
    updateCurrent(data);
  };

  return (
    <>
      <div className="text-center">
        <img
          className="text-center"
          src={pincel}
          width={"20px"}
          onClick={() => setIsOpen(true)}
        />
        {isOpen && (
          <div>
            <div className="main-container-color">
              <div className="box-popover">
                <Popover
                  className="popover-colors"
                  content={
                    <div>
                      <div className="d-flex m-b20">
                        <p className="m-0">Definir cor</p>
                        <button
                          onClick={() => setIsOpen(false)}
                          className="btn btn-primary m-l20 btnhover p-1"
                        >
                          Fechar
                        </button>
                      </div>
                      <div className="color-buttons">
                        <button
                          value="null"
                          className="btn p-3  border"
                          onClick={() =>
                            onSave({
                              id: item._id,
                              data: null,
                              type: "color",
                            })
                          }
                        ></button>
                        <button
                          value="red"
                          className="btn p-3 red-date border"
                          onClick={() =>
                            onSave({
                              id: item._id,
                              data: "red",
                              type: "color",
                            })
                          }
                        ></button>
                        <button
                          value="green"
                          className="btn p-3 green-date border"
                          onClick={() =>
                            onSave({
                              id: item._id,
                              data: "green",
                              type: "color",
                            })
                          }
                        ></button>
                        <button
                          value="yellow"
                          className="btn p-3 yellow-date border"
                          onClick={() =>
                            onSave({
                              id: item._id,
                              data: "yellow",
                              type: "color",
                            })
                          }
                        ></button>
                      </div>
                    </div>
                  }
                  trigger="click"
                  open={isOpen}
                ></Popover>
              </div>
            </div>
            <div className="background-colors"></div>
          </div>
        )}
      </div>
    </>
  );
}

import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import bg1 from "./../assets/images/background/bg1.jpg";
import api from "../services/config.url";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";

function NewsLetter({ subscribeChange }) {
  const { setLoading } = useContext(AuthContext);
  const { register, handleSubmit, reset } = useForm();

  const sendNewsMessage = (data) => {
    const number = data.whatsappNumber.replace(/[^\d]/g, "");
    if (number.length < 11) {
      showPopup("Número de celular inválido", "error");
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        await api.post(`/customers/message/news`, {
          ...data,
          whatsappNumber: number,
        });
        showPopup("Enviado com sucesso", "success");
        reset();
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Não foi possível enviar a mensagem";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  return (
    <>
      <section
        className={`py-5 newsletter-wrapper ${subscribeChange}`}
        // style={{ backgroundImage: "url(" + bg1 + ")", backgroundSize: "cover" }}
      >
        <div className="container">
          <div className="subscride-inner">
            <div className="row style-1 justify-content-xl-between justify-content-lg-center align-items-center text-xl-start text-center">
              <div className="col-12 col-lg-5 col-xl-6">
                <div className="section-head mb-0">
                  <h2 className="title text-white my-lg-3 mt-0">
                    Quer receber cupons de desconto e novidades? Deixe seu
                    contato aqui!
                  </h2>
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-6">
                <form
                  className="dzSubscribe style-1"
                  onSubmit={handleSubmit(sendNewsMessage)}
                >
                  <div className="dzSubscribeMsg"></div>
                  <div className="form-group">
                    <div className="input-group newsletter mb-0">
                      <InputMask
                        mask="(99) 99999-9999"
                        name="dzWpp"
                        autoComplete="off"
                        required="required"
                        type="text"
                        className="form-control bg-transparent text-white"
                        placeholder="Seu Whatsapp"
                        {...register("whatsappNumber")}
                      />
                      <input
                        name="dzEmail"
                        autoComplete="off"
                        required="required"
                        type="email"
                        className="form-control bg-transparent text-white"
                        placeholder="Seu E-mail"
                        {...register("email")}
                      />
                      <div className="box-button">
                        <button
                          name="submit"
                          value="Submit"
                          type="submit"
                          className="btn btn-primary btnhover"
                        >
                          <span>Enviar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default NewsLetter;

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/pt-br";
dayjs.extend(utc);
dayjs.extend(timezone);

const InputText = (props) => {
  const { item, updateCurrent, className, action = true } = props;

  const [date, setDate] = useState(null);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (item.observation) {
      setDate(item.observation);
    }
  }, [item.observation]);

  const onSave = () => {
    setCanSave(false);
    setDate(null);
    const body = {
      id: item._id,
      data: item.observation,
      item: item,
    };

    updateCurrent(body, "observation");
  };

  const handleUpdate = (date) => {
    setDate(date);
    if (!canSave) {
      setCanSave(true);
    }
  };

  return (
    <div className="relative">
      {action && canSave && (
        <button
          className="btn-save-cad text-center btn-primary"
          onClick={() => onSave()}
        >
          ok
        </button>
      )}
      <input
        className="noBreakLine"
        placeholder={!item.observation && "Observações..."}
        defaultValue={item.observation || ""}
        onChange={(e) => {
          const newValue = e.target.value;
          item.observation = newValue;
          handleUpdate(e.target.value);
        }}
      />
    </div>
  );
};

export default InputText;

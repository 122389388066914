import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contexts/auth";
import api from "../services/config.url";
import { FormattedPrice } from "../components/FormatValues";
import showPopup from "../components/PopUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link, useHistory } from "react-router-dom";
//Component
import PageTitle from "../layouts/PageTitle";
import NewsLetter from "../components/NewsLetter";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function Combos() {
  const { user, setCart, cart, setSizeCart, setLoading } =
    useContext(AuthContext);
  const [response, setResponse] = useState([]);
  const navegate = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({
    current: currentPage,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      const formattedQuery = new URLSearchParams(query).toString();
      try {
        setLoading(true);
        const response = await api.get(`/customers/combos?${formattedQuery}`);
        setResponse(response.data);
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError = "Erro ao listar os combos disponíveis";
        showPopup(BackendError || genericError, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);

  const addCart = async (data) => {
    if (!user) {
      showPopup("Acesse sua conta para adicionar combos ao carrinho", "alert");
      return navegate.push("/acesso");
    }

    const cartId = cart._id;
    setLoading(true);
    try {
      const response = await api.post(`/customers/cart/${cartId}/item`, {
        combo: data._id,
        amountUnit: data.amountTotal,
        sku: data.sku,
        name: data.name,
        description: data.description,
      });
      setSizeCart(response.data.items.length);
      setCart(response.data);
      showPopup("Combo adicionado ao carrinho", "success");
    } catch (error) {
      if (error?.response?.data?.message === "Unauthorized") {
        showPopup("Faça login novamente", "error");
        return navegate.push("/acesso");
      }
      showPopup(error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
    setQuery({
      ...query,
      current: page,
    });
  };

  return (
    <div className="header-padding-main">
      <PageTitle parentPage="CJ" childPage="Combos" />
      <div className="page-content bg-grey collapse-shop-list">
        <div className="container mb-5">
          {response.records?.map((combo, index) => (
            <Accordion key={index} className="col-12 m-b10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="headerColapse">
                  <strong className="p-l10">
                    <span>{combo?.name}</span>{" "}
                  </strong>
                  <strong>
                    <FormattedPrice price={combo.amountTotal} />
                  </strong>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {combo?.products.map((item, index) => (
                  <Accordion key={index} className="col-12 m-b10">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="headerColapse">
                        <strong className="p-l10">
                          <span>{item?.name}</span>{" "}
                        </strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item?.childrens?.map((item, index) => (
                        <div key={index} className="dz-shop-card style-2">
                          <div className="dz-header">
                            <div>
                              <p className="title mb-0">
                                <p className="m-0">
                                  {item?.sku} - {item?.name}
                                </p>
                              </p>
                            </div>
                          </div>
                          <div className="dz-body">
                            <div className="dz-rating-box">
                              <div>
                                <p className="dz-para">{item?.description}</p>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>

                  // <div key={index} className="dz-shop-card style-2">

                  //   <div className="dz-header">
                  //     <div>
                  //       <p className="title mb-0">
                  //         <p className="m-0">
                  //           {item?.sku} - {item?.name}
                  //         </p>
                  //       </p>
                  //     </div>
                  //   </div>
                  //   <div className="dz-body">
                  //     <div className="dz-rating-box">
                  //       <div>
                  //         <p className="dz-para">{item?.description}</p>
                  //       </div>
                  //     </div>
                  //     {/* </div> */}
                  //   </div>
                  // </div>
                ))}
                <div className="payment-combo p-b50">
                  <div className="d-flex flex-column text-center">
                    <div className="m-0" style={{ fontSize: "12px" }}>
                      {combo.originalAmount && (
                        <p className="price-combo">
                          <FormattedPrice price={combo?.originalAmount} />
                        </p>
                      )}
                    </div>
                    <h4 className="m-l20 m-r20">
                      <FormattedPrice price={combo.amountTotal} />
                    </h4>
                  </div>
                  <div className="rate" onClick={() => addCart(combo)}>
                    <ul className="book-info"></ul>
                    <div className="d-flex">
                      <Link
                        to={"carrinho"}
                        className="btn btn-secondary btnhover btnhover2"
                      >
                        <i className="flaticon-shopping-cart-1 m-r10"></i>{" "}
                        Adquirir combo{" "}
                      </Link>
                      <div className="bookmark-btn style-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div className="pagination-component ">
          <Stack spacing={2}>
            <Pagination
              count={response.pages}
              page={currentPage}
              onChange={(event, page) => handlePagination(page)}
            />
          </Stack>
        </div>
      </div>
      <NewsLetter />
    </div>
  );
}
export default Combos;

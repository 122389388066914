import React, { useState, useContext } from "react";
import AuthContext from "../contexts/auth";
import api from "../services/config.url";
import { Form } from "react-bootstrap";
import showPopup from "../components/PopUp";
import InputMask from "react-input-mask";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Payments({ currentOrder, readyToPayment }) {
  const publicKey = "APP_USR-8dc5bccf-1da7-4ad4-90da-9fbd59bd61ee";

  const { cart, setLoading, setSizeCart } = useContext(AuthContext);
  const navigate = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [infoPayments, setInfoPayments] = useState({});
  const [pixQrCode, setPixQrCode] = useState({
    qr_code: null,
    qr_code_base64: null,
  });
  const [installmentsResponse, setInstallmentsResponse] = useState({
    method: "",
    installments: [],
    thumbnail: "",
  });

  const validations = async () => {
    const requiredFields = [
      "method",
      "cardNumber",
      "cvv",
      "name",
      "validity",
      "installments",
      "cpf",
    ];
    const response = {};
    const invalidCardMessage = "Número de cartão inválido";
    const cardNumberLength = infoPayments?.cardNumber?.replace(
      /\D+/g,
      ""
    ).length;

    if (cardNumberLength < 15 || cardNumberLength > 16) {
      response.isValid = false;
      response.message = invalidCardMessage;
      return response;
    }

    for (const field of requiredFields) {
      if (!infoPayments[field]) {
        response.isValid = false;
        response.message = "Preencha todos os campos";
        return response;
      }
    }

    response.isValid = true;
    return response;
  };
  const creditCardPayments = async (_id, uuid, token) => {
    try {
      await api.post(`/customers/payment/card/${_id}/${uuid}`, {
        installments: infoPayments.installments,
        token,
      });

      showPopup("Pedido gerado com sucesso", "success");
      readyToPayment(true);
      navigate.push("/pedidos");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError =
        "Não foi possivel realizar a transação por cartão de crédito";
      showPopup(BackendError || genericError, "error");
    } finally {
      navigate.push("/meus-livros/aluno");
    }
  };
  const pixPayments = async (_id, uuid) => {
    try {
      const response = await api.post(`/customers/payment/pix/${_id}/${uuid}`);
      setPixQrCode(response?.data);
      readyToPayment(true);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Não foi possivel realizar a transação por Pix";
      showPopup(BackendError || genericError, "error");
    }
  };
  const mpPayments = async (_id, uuid) => {
    try {
      const response = await api.post(`/customers/payment/mp/${_id}/${uuid}`);
      handleMPPayment(response.data.url);
      readyToPayment(true);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError =
        "Não foi possivel realizar a transação através do Mercado Pago";
      showPopup(BackendError || genericError, "error");
    } finally {
      navigate.push("/meus-livros/aluno");
    }
  };
  const getTokenPayment = async () => {
    const cardExpirationMonth = Number(infoPayments?.validity.split("/")[0]);
    const cardExpirationYear = Number(infoPayments?.validity.split("/")[1]);
    try {
      const response = await axios.post(
        `https://api.mercadopago.com/v1/card_tokens?public_key=${publicKey}&locale=pt-BR&js_version=2.0.0`,
        {
          card_number: infoPayments.cardNumber.replace(/\D+/g, ""),
          cardholder: {
            name: infoPayments.name,
            identification: {
              type: "CPF",
              number: infoPayments.cpf.replace(/\D+/g, ""),
            },
          },
          security_code: infoPayments.cvv,
          expiration_month: cardExpirationMonth,
          expiration_year: cardExpirationYear,
        }
      );
      return response.data.id;
    } catch {
      showPopup("Não foi possivel gerar o token de pagamento", "error");
    }
  };
  //CRIA O PEDIDO JA COM A FORMA DE PAGAMENTO SELECIONADA
  const createOrder = async () => {
    if (cart.amountTotal <= 0) {
      // Ignora a parte de pagamento pois o item saiu sem custo
      try {
        setLoading(true);
        await api.post(`/customers/orders`, {
          cart: cart._id,
        });
        showPopup("Compra concluída! Baixe o CAD em Meus Cadernos!", "success");
      } catch (e) {
      } finally {
        setSizeCart(0);
        setLoading(false);
        readyToPayment(true);
        navigate.push("/meus-livros/aluno");
      }
      return;
    }

    if (!infoPayments.method) {
      return showPopup("Selecione a forma de pagamento", "alert");
    }
    //Valida os dados do cartão
    if (infoPayments.method === "creditCard") {
      const isValidCard = await validations();
      if (!isValidCard.isValid) return showPopup(isValidCard.message, "error");
    }

    setLoading(true);
    try {
      let _id;
      let uuid;

      //Verifico se a variavel currentOrder existe, se existir significa que o pagamento esta sendo feito pela tela de Orders
      if (!currentOrder) {
        //Cart
        const response = await api.post(`/customers/orders`, {
          cart: cart._id,
        });
        _id = response.data._id;
        uuid = response.data.uuid;
      } else {
        //Order
        _id = currentOrder._id;
        uuid = currentOrder.uuid;
      }

      //pagamento com Mercado pago
      if (infoPayments.method === "Mercado Pago") {
        await mpPayments(_id, uuid);
      }
      // //pagamento com Pix
      if (infoPayments.method === "pix") {
        await pixPayments(_id, uuid);
      }
      //pagamento com Cartão de crédito
      if (infoPayments.method === "creditCard") {
        const tokenPayment = await getTokenPayment();
        await creditCardPayments(_id, uuid, tokenPayment);
      }

      setSizeCart(0);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError =
        "ocorreu um erro ao gerar seu pedido, entre em contato com o administrador do sistema.";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      setSizeCart(0);
    }
  };
  //DEFINE O TIPO DE PAGAMENTO E INSERE NO STATE (infoPayments)
  const handleChange = (event) => {
    setInfoPayments({ ...infoPayments, method: event.target.value });
    setPaymentMethod(event.target.value === "creditCard" || false);
  };
  const copyToClipboard = async () => {
    if (pixQrCode.qr_code) {
      navigator.clipboard.writeText(pixQrCode.qr_code);
      showPopup("Código Pix copiado com sucesso", "success");
    } else {
      showPopup("Não foi possível copiar o QRcode", "error");
    }
  };
  const handleMPPayment = async (url) => {
    if (!url)
      showPopup(
        "Não foi possível obter os dados da transação. Tente novamente mais tarde.",
        "error"
      );
    if (url) {
      window.location.href = url;
    }
  };
  //CARREGA AS PARCELAS DO CARTÂO DE CRÈDITO
  const handleCard = async (event) => {
    const BIN = event.replace(/\D+/g, "").slice(0, 6);

    //Se houver currentOrder, então eu pego o valor total da order que esta na tabela order senão pego do carrinho
    const amountOrder = !currentOrder
      ? cart.amountTotal
      : currentOrder.amountTotal;

    if (BIN.length === 6) {
      const { data } = await axios.get(
        `https://api.mercadopago.com/v1/payment_methods/installments?public_key=${publicKey}&locale=pt-BR&js_version=2.0.0&referer=https%3A%2F%2Fpagamento.cadernosjuridicos.com.br&bin=${BIN}&processing_mode=aggregator&payment_type_id=&locale=pt-BR&amount=${amountOrder}`
      );
      data.map((item) => {
        return setInstallmentsResponse({
          method: item.payment_method_id,
          installments: item.payer_costs,
          thumbnail: item.issuer.thumbnail,
        });
      });
    }
  };
  const handleValidity = async (event) => {
    const value = event;
    const month = value.slice(0, 2);
    const year = value.slice(3);
    const formattedValue = `${month}/20${year}`;
    return setInfoPayments({
      ...infoPayments,
      validity: formattedValue,
    });
  };

  return (
    <>
      <div className="col-lg-6 m-t50 widget">
        <h4 className="widget-title">FORMA DE PAGAMENTO</h4>

        {!pixQrCode.qr_code_base64 && (
          <>
            <div className="form-group m-t10 m-b10">
              {cart.amountTotal > 0 ? (
                <Form.Select
                  aria-label="Credit Card Type"
                  onChange={handleChange}
                >
                  <option>Selecione...</option>
                  <option value="creditCard">Cartão de crédito</option>
                  <option value="pix">Pix</option>
                  <option
                    value="Mercado Pago"
                    onChange={(e) => setInfoPayments("mercadoPago")}
                  >
                    Mercado Pago
                  </option>
                </Form.Select>
              ) : (
                <>
                  Carrinho com desconto de <strong>100%</strong>, finalize seu
                  pedido sem custos adicionais!
                </>
              )}
            </div>
            {paymentMethod && (
              <>
                <div className={`form-group`}>
                  <div className="thumbnailCard">
                    <InputMask
                      mask={
                        installmentsResponse.method === "amex"
                          ? "9999 999999 99999"
                          : "9999 9999 9999 9999"
                      }
                      maskChar={null}
                      type="text"
                      className="form-control"
                      placeholder={"Número do cartão de crédito"}
                      onBlur={(e) => handleCard(e.target.value)}
                      onChange={(e) =>
                        setInfoPayments({
                          ...infoPayments,
                          cardNumber: e.target.value,
                        })
                      }
                    />
                    {installmentsResponse.thumbnail && (
                      <img
                        src={installmentsResponse.thumbnail}
                        alt=""
                        objectfit="contain"
                      />
                    )}
                  </div>
                </div>

                <div className={`form-group m-t10`}>
                  <InputMask
                    type="text"
                    className="form-control"
                    placeholder={"Nome do titular"}
                    value={infoPayments?.name?.toUpperCase()}
                    onChange={(e) =>
                      setInfoPayments({
                        ...infoPayments,
                        name: e.target.value
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, ""),
                      })
                    }
                  />
                </div>
                <div className={`form-group m-t10`}>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar={null}
                    type="text"
                    className="form-control"
                    placeholder={"CPF"}
                    onChange={(e) =>
                      setInfoPayments({
                        ...infoPayments,
                        cpf: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex justify-content-between m-t10 gap-2">
                  <div className={`form-group w-50`}>
                    <InputMask
                      mask="99/99"
                      maskChar={null}
                      type="text"
                      className="form-control"
                      placeholder={"Validade"}
                      onChange={(e) => handleValidity(e.target.value)}
                      // onChange={(e) =>
                      //   setInfoPayments({
                      //     ...infoPayments,
                      //     validity: e.target.value,
                      //   })
                      // }
                    />
                  </div>
                  <div className={`form-group w-50`}>
                    <InputMask
                      type="text"
                      mask={
                        installmentsResponse.method === "amex" ? "9999" : "999"
                      }
                      maskChar={null}
                      className="form-control"
                      placeholder={"CVV"}
                      onChange={(e) =>
                        setInfoPayments({
                          ...infoPayments,
                          cvv: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`form-group m-t10 w-100`}>
                  <Form.Select
                    disabled={installmentsResponse.installments.length === 0}
                    onChange={(val) =>
                      setInfoPayments({
                        ...infoPayments,
                        installments: Number(val.target.value),
                      })
                    }
                  >
                    <option defaultValue="">Selecione...</option>
                    {installmentsResponse.installments.map((payerCost) => (
                      <option
                        key={payerCost.installments}
                        value={payerCost.installments}
                      >
                        {payerCost.recommended_message}{" "}
                        {payerCost.installment_rate > 0
                          ? " - Com Juros"
                          : " - Sem Juros"}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </>
            )}

            <div className="form-group m-b10">
              <button
                className="btn btn-primary btnhover m-t100"
                type="button"
                onClick={createOrder}
              >
                Finalizar pedido{" "}
              </button>
            </div>
          </>
        )}

        {!!pixQrCode.qr_code_base64 && (
          <div className="pixPayment">
            <img src={`data:image/png;base64,${pixQrCode.qr_code_base64}`} />
            <button onClick={() => copyToClipboard()}>Copiar código pix</button>
          </div>
        )}
      </div>
    </>
  );
}
export default Payments;

import React, { useState, useContext, useCallback, useEffect } from "react";
import AuthContext from "../../contexts/auth";
import showPopup from "../PopUp";
import { useForm } from "react-hook-form";
import api from "../../services/config.url";
import { TimePicker } from "antd";
import dayjs from "dayjs";
const format = "HH:mm";

export default function ModalMetas({
  open,
  onClose,
  internalGoal,
  punctuation,
}) {
  // const { setLoading } = useContext(AuthContext);
  const [internalGoalScore, setInternalGoalScore] = useState(internalGoal);
  const { register, handleSubmit, setValue, reset } = useForm();

  const [studyDaysHours, setStudyDaysHours] = useState(
    punctuation?.studyDaysHours || {}
  );

  useEffect(() => {
    if (
      internalGoalScore &&
      internalGoalScore.nameOfWeek !== internalGoal.nameOfWeek
    ) {
      reset();
      setInternalGoalScore(internalGoal);
    }
  }, [open, internalGoal, internalGoalScore]);

  useEffect(() => {
    setStudyDaysHours(punctuation.studyDaysHours);
  }, [open]);

  const toHour = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes
    }`;
  };

  const toMinutes = (value) => {
    if (!value) return 0;
    if (value === 0 || value?.length <= 3) return 0;
    if (typeof value === "string") {
      const [hours, minutes] = value.split(":");
      return parseInt(hours) * 60 + parseInt(minutes);
    }
  };

  const handleBtn = (type) => {
    if (studyDaysHours.hasOwnProperty(type)) {
      const current = studyDaysHours[type];
      const newCurrent = !current;
      setStudyDaysHours({ ...studyDaysHours, [type]: newCurrent });
    }
  };

  const closeModal = useCallback(() => {
    setInternalGoalScore([]);
    onClose();
  }, [onClose]);

  const updateInternalGoals = async (data) => {
    const PanelParams = {
      nameOfWeek: internalGoalScore.nameOfWeek,
      goalCad: data.cad || internalGoalScore.goalCad,
      goalInfo: data.info || internalGoalScore.goalInfo,
      goalQc: data.qc || internalGoalScore.goalQc,
      studyDaysHours,
    };

    try {
      // setLoading(true);
      const response = await api.patch(
        `/customers/punctuation/${punctuation._id}`,
        {
          internalGoals: PanelParams,
        }
      );
      setInternalGoalScore(response?.data?.internalGoals);
      showPopup("Painel atualizado com sucesso.", "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao atualizar suas pontuações";
      showPopup(BackendError || genericError, "error");
    } finally {
      reset();
      // setLoading(false);
      closeModal();
    }
  };

  const ModalMetasContent = () => {
    return (
      <form
        onSubmit={handleSubmit(updateInternalGoals)}
        style={{ position: "relative" }}
      >
        <div className="box-modal p-3">
          <button
            className="btn btn-primary btnhover f-right p-2"
            onClick={onClose}
            style={{ height: "30px", width: "30px" }}
          >
            X
          </button>
          <div>
            <div className="modal-bloco">
              <p className="text-bold">Meta de HORAS para CAD, INFO e QC: </p>
            </div>
            <div className="modal-bloco">
              <p className="text-bold">Cadernos (CAD): </p>
              <TimePicker
                defaultValue={dayjs(
                  toHour(internalGoalScore?.goalCad || 0),
                  format
                )}
                inputReadOnly={true}
                format={format}
                showNow={false}
                {...register("cad")}
                onChange={(e, time) => {
                  setValue("cad", toMinutes(time));
                }}
                style={{ height: 40, width: 100 }}
                size="large"
              />
            </div>
            <div className="modal-bloco">
              <p className="text-bold">Informativos (INFO): </p>
              <TimePicker
                defaultValue={dayjs(
                  toHour(internalGoalScore?.goalInfo || 0),
                  format
                )}
                inputReadOnly={true}
                format={format}
                showNow={false}
                onChange={(e, time) => {
                  setValue("info", toMinutes(time));
                }}
                style={{ height: 40, width: 100 }}
                size="large"
              />
            </div>
            <div className="  modal-bloco">
              <p className="text-bold">Questões (QC): </p>
              <TimePicker
                defaultValue={dayjs(
                  toHour(internalGoalScore?.goalQc || 0),
                  format
                )}
                inputReadOnly={true}
                format={format}
                showNow={false}
                onChange={(e, time) => {
                  setValue("qc", toMinutes(time));
                }}
                style={{ height: 40, width: 100 }}
                size="large"
              />
            </div>
          </div>

          <div className="studyDays">
            <p
              className={
                internalGoalScore?.nameOfWeek === "segunda-feira"
                  ? "checked"
                  : ""
              }
              onClick={() => handleBtn("monday")}
            >
              SEG
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "terça-feira" ? "checked" : ""
              }
              onClick={() => handleBtn("tuesday")}
            >
              TER
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "quarta-feira"
                  ? "checked"
                  : ""
              }
              onClick={() => handleBtn("wednesday")}
            >
              QUA
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "quinta-feira"
                  ? "checked"
                  : ""
              }
              onClick={() => handleBtn("thursday")}
            >
              QUI
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "sexta-feira" ? "checked" : ""
              }
              onClick={() => handleBtn("friday")}
            >
              SEX
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "sábado" ? "checked" : ""
              }
              onClick={() => handleBtn("saturday")}
            >
              SÁB
            </p>
            <p
              className={
                internalGoalScore?.nameOfWeek === "domingo" ? "checked" : ""
              }
              onClick={() => handleBtn("sunday")}
            >
              DOM
            </p>
          </div>
          <div className="buttons-modal">
            <button
              className="btn btn-primary btnhover f-right m-0 "
              type="submit"
            >
              SALVAR
            </button>
          </div>
        </div>
      </form>
    );
  };

  if (open) {
    document.body.classList.add("disable-scroll");
  }
  if (!open) {
    document.body.classList.remove("disable-scroll");
    return null;
  }

  return (
    <>
      <div className="backgroundDark" onClick={onClose}></div>
      <ModalMetasContent />
    </>
  );
}

import React, { useState, createContext, useCallback, useEffect } from "react";
import api from "../services/config.url";
import showPopup from "../components/PopUp";

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [global, setGlobal] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const getGlobals = useCallback(async () => {
    try {
      const global = await api.get("customers/global/context");
      setGlobal(global.data);
    } catch (error) {
      // const genericError = "Erro ao carregar as configurações globais";
      // return showPopup(genericError, "error");
    }
  }, []);

  useEffect(() => {
    if (!dataLoaded) {
      getGlobals();
      setDataLoaded(true);
    }
  }, [dataLoaded, getGlobals]);

  return (
    <GlobalContext.Provider
      value={{
        global,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;

import React from "react";
import Index from "./pages/Index";

//Css
import "./assets/css/style.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import { ConfigProvider } from "antd";

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{ token: { colorPrimary: "#102e3b", fontSize: 15 } }}
      >
        <Index />
      </ConfigProvider>
    </div>
  );
}

export default App;

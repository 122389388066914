import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo2.png";

function Footer({ footerChange }) {
  return (
    <>
      <footer className={`site-footer ${footerChange}`}>
        <div
          className="version"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: 12 }}>
            Copyright Cadernos Jurídicos © 2023 (v1.1.0)
          </span>
        </div>

        <div className="right-flag flag-style"></div>
        <div className="left-flag flag-style"></div>
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-12 col-sm-11 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"/"}>
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div className="" data-wow-delay="0.5s">
                    <div className="dz-social-icon style-1 p-b20">
                      <ul>
                        <li>
                          <a
                            href="https://www.instagram.com/cadernosjuridicos/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/cadernosjuridicos"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://wa.me/556295584021/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className=" wow fadeInUp pl-3" data-wow-delay="0.2s">
                  <div className="widget widget_services">
                    <ul>
                      <li>
                        <Link to={"/sobre-nos"}>Sobre nós</Link>
                      </li>
                      <li>
                        <Link to={"/contato"}>Fale conosco</Link>
                      </li>
                      <li>
                        <Link to={"/lista-de-cadernos"}>Lista de Cadernos</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;

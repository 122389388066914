import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ReactDOM from "react-dom";
import errorPopup from "../assets/images/errorPopup.png";
import success from "../assets/images/success.png";
import alert from "../assets/images/alert.png";

const fadeInOut = keyframes`
	0% {
	  bottom: -200px;
	}
	20% {
	  bottom: 20px;
	}
	80% {
	  bottom: 20px;
	}
	100% {
	  bottom: -200px;
	}
`;

const PopupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  z-index: 999999;
  width: max-content;
  max-width: 70vw;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 3px 1px 0px #b3b3b3;
  animation: ${fadeInOut} 5s;
  word-break: break-word;
  @media (max-width: 700px) {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PopupSuccess = styled(PopupWrapper)`
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  img {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    img {
      margin-right: 0px;
    }
  }
`;

const PopupError = styled(PopupWrapper)`
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebcccc;
  img {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    img {
      margin-right: 0px;
    }
  }
`;
const PopupAlert = styled(PopupWrapper)`
  background-color: #fbf4d6;
  color: #83871b;
  border: 1px solid #f5f5f5;
  img {
    margin-right: 20px;
  }
  @media (max-width: 700px) {
    img {
      margin-right: 0px;
    }
  }
`;

function Popup({ message, type }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 4500);
  }, []);

  if (!visible) {
    return null;
  }
  return (
    <>
      {type === "success" ? (
        <PopupSuccess>
          <img src={success} />
          <span>{message}</span>
        </PopupSuccess>
      ) : type === "error" ? (
        <PopupError>
          <img src={errorPopup} />
          <span>{message}</span>
        </PopupError>
      ) : (
        <PopupAlert>
          <img src={alert} />
          <span>{message}</span>
        </PopupAlert>
      )}
    </>
  );
}

export default function showPopup(message, type) {
  const anchorElement = document.createElement("div");
  document.body.appendChild(anchorElement);
  ReactDOM.render(<Popup message={message} type={type} />, anchorElement);
}

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../contexts/auth";
import { FormattedPrice } from "../components/FormatValues";
import PageTitle from "./../layouts/PageTitle";
import book1 from "./../assets/images/books/grid/imageNotFound.jpg";
import Payments from "../components/payments";

function ShopCheckout() {
  // const publicKey = "APP_USR-8dc5bccf-1da7-4ad4-90da-9fbd59bd61ee";

  const { cart } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const [cartDetail, setCartDetail] = useState([]);

  useEffect(() => {
    if (!cart) return;

    setCartItems(cart.items);
    setCartDetail(cart);
  }, [cart]);

  const ResumeOrder = () => {
    if (cartItems.length < 1) {
      return <div>Vazio</div>;
    }

    const subTotal = cartItems.reduce(
      (total, value) => total + parseFloat(value.amountUnit),
      0
    );

    return (
      <table className="table-bordered check-tbl mb-4">
        <tbody>
          <tr>
            <td>Subtotal do pedido</td>
            <td className="product-price">
              <FormattedPrice price={subTotal} />
            </td>
          </tr>

          <tr>
            <td>Cupom</td>
            <td className="product-price">
              <FormattedPrice price={cart.discount} />
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="product-price-total">
              <FormattedPrice price={Math.max(0, subTotal - cart.discount)} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="header-padding-main">
      <div className="page-content">
        <PageTitle parentPage="CJ" childPage="Checkout" />
        <section className="content-inner-1">
          <div className="container">
            {cartItems?.length >= 1 && (
              <>
                <div className="dz-divider bg-gray-dark text-gray-dark icon-center  my-5">
                  <i className="fa fa-circle bg-white text-gray-dark"></i>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="widget">
                      <h4 className="widget-title">Seu pedido</h4>
                      <table className="table-bordered check-tbl">
                        <thead className="text-center">
                          <tr>
                            <th colSpan={2} className="text-left">
                              NOME DO PRODUTO
                            </th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((item, index) => (
                            <tr key={index}>
                              <td className="product-item-img">
                                <img
                                  src={
                                    (item.photos &&
                                      item.photos[0] &&
                                      item.photos[0].photo &&
                                      item.photos[0].photo.url) ||
                                    book1
                                  }
                                  alt=""
                                />
                              </td>
                              <td className="product-item-name">{item.name}</td>
                              <td className="product-price">
                                {item.amountUnit == 0 ? (
                                  <>
                                    <p className="bonus">Incluso</p>
                                  </>
                                ) : (
                                  <FormattedPrice price={item.amountUnit} />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-12 m-t50">
                    <form className="shop-form widget">
                      <h4 className="widget-title">TOTAL DO PEDIDO</h4>
                      <ResumeOrder />
                    </form>
                  </div>
                </div>
              </>
            )}
            <Payments />
          </div>
        </section>
      </div>
    </div>
  );
}
export default ShopCheckout;

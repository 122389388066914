import React, { useState } from "react";

const ConfirmationModal = ({ message, onConfirm, onCancel, isModalOpen }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {isModalOpen && (
        <div className="container-modal-confirmation">
          <div className="layout-delete">
            <p>{message}</p>
            <div className="buttons">
              <button
                className="deleteBtnCad btnhover"
                style={{ marginTop: 0 }}
                onClick={handleCancel}
              >
                Cancelar
              </button>
              <button
                className="deleteBtnConfirm btn-primary btnhover"
                onClick={handleConfirm}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;

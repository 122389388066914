import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import api from "../services/config.url";

//Components
import PageTitle from "./../layouts/PageTitle";

//Context
import AuthContext from "./../contexts/auth";

function Login() {
  const [forgotPass, setForgotPass] = useState();
  const { register, handleSubmit } = useForm();
  const { signIn } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const boxModal = {
    zIndex: "99999",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const login = async (data) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    const email = data.login.match(emailRegex) ? data.login : undefined;
    const nickname = email ? undefined : data.login;
    const payload = { ...data, email, nickname };
    await signIn(payload, { redirection: true });
  };

  const recoveryPassword = async (data) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    const email = data.login.match(emailRegex) ? data.login : undefined;
    const payload = { email };
    try {
      api.post(`/customers/auth/password-recovery`, payload).then((res) => {
        if (res) {
          return "solicitado com sucesso";
        }
      });
      showPopup(
        "Solicitação enviada! Verifique sua caixa de entrada",
        "success"
      );
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao solicitar a nova senha.";
      showPopup(BackendError || genericError, "error");
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <div className="header-padding-main">
      <div>
        <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={boxModal}>
          <Box sx={style}>
            <div>
              <div className="layout-payments">
                <div className="tab-content nav">
                  <form
                    onSubmit={handleSubmit(recoveryPassword)}
                    className={` col-12 ${forgotPass ? "d-none" : ""}`}
                  >
                    <h4 className="text-secondary">Alteração de senha</h4>

                    <div className="mb-4">
                      <label className="label-title">E-mail *</label>
                      <input
                        {...register("login")}
                        required=""
                        className="form-control"
                        placeholder="Seu usuário ou email"
                        type="text"
                      />
                    </div>

                    <div className="text-left">
                      <button
                        type="submit"
                        className="btn btn-primary btnhover me-2"
                      >
                        Solicitar
                      </button>
                    </div>
                  </form>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className={`  col-12 ${forgotPass ? "" : "d-none"}`}
                  >
                    <h4 className="text-secondary">Esqueceu sua senha ?</h4>
                    <p className="font-weight-600">
                      Enviaremos um email para redefinir sua senha.{" "}
                    </p>
                    <div className="mb-3">
                      <label className="label-title">E-MAIL *</label>
                      <input
                        name="dzName"
                        required=""
                        className="form-control"
                        placeholder="Seu email"
                        type="email"
                      />
                    </div>
                    <div className="text-left">
                      <Link
                        to={""}
                        className="btn btn-outline-secondary btnhover m-r10 active"
                        onClick={() => setForgotPass(!forgotPass)}
                      >
                        Voltar
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary btnhover"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="page-content">
        <PageTitle parentPage="CJ" childPage="Login" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <div className="tab-content">
                    <h4>Novo cliente</h4>
                    <p>Cadastre-se para acessar aos recursos do site!</p>
                    <Link
                      to={"cadastre-se"}
                      className="btn btn-primary btnhover m-r5 button-lg radius-no"
                    >
                      Cadastre-se
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <div className="tab-content nav">
                    <form
                      onSubmit={handleSubmit(login)}
                      className={` col-12 ${forgotPass ? "d-none" : ""}`}
                    >
                      <h4 className="text-secondary">Login</h4>
                      <p className="font-weight-600">
                        Caso já possua uma conta, faça seu login.
                      </p>
                      <div className="mb-4">
                        <label className="label-title">
                          Usuário ou E-mail *
                        </label>
                        <input
                          {...register("login")}
                          required=""
                          className="form-control"
                          placeholder="Seu E-mail"
                          type="text"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="label-title">SENHA *</label>
                        <input
                          {...register("password")}
                          required=""
                          className="form-control "
                          placeholder="Digite sua senha"
                          type="password"
                        />
                      </div>
                      <div className="text-left">
                        <button
                          type="submit"
                          className="btn btn-primary btnhover me-2"
                        >
                          Entrar
                        </button>
                        <span
                          className="m-l5 pointer"
                          onClick={() => setIsOpen(true)}
                        >
                          <i className="fas fa-unlock-alt"></i> Esqueci minha
                          senha
                        </span>
                      </div>
                    </form>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className={`  col-12 ${forgotPass ? "" : "d-none"}`}
                    >
                      <h4 className="text-secondary">Esqueceu sua senha ?</h4>
                      <p className="font-weight-600">
                        Enviaremos um email para redefinir sua senha.{" "}
                      </p>
                      <div className="mb-3">
                        <label className="label-title">E-MAIL *</label>
                        <input
                          name="dzName"
                          required=""
                          className="form-control"
                          placeholder="Seu email"
                          type="email"
                        />
                      </div>
                      <div className="text-left">
                        <Link
                          to={""}
                          className="btn btn-outline-secondary btnhover m-r10 active"
                          onClick={() => setForgotPass(!forgotPass)}
                        >
                          Voltar
                        </Link>
                        <button
                          type="submit"
                          className="btn btn-primary btnhover"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Login;

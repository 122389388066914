import React, { useContext } from "react";
import AuthContext from "../../../contexts/auth";
import { useForm } from "react-hook-form";
import showPopup from "../../PopUp";
import api from "../../../services/config.url";

export default function CreateInfoModal({ onCancel, isModalOpen, parent }) {
  const { setLoading } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();

  const postInformative = async (data) => {
    let payload = {
      ...data,
      parent: parent._id,
    };

    try {
      setLoading(true);
      await api.post(`/customers/revisions-info`, payload);
      const message = "Observação criada com sucesso";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao criar observação para o informativo";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {isModalOpen && (
        <div className="container-modal-books">
          <div className="bg-modal">
            <form onSubmit={handleSubmit(postInformative)}>
              <div className="create-modal-layout">
                <label className="m-b10">Tipo de registro</label>
                <input
                  name="infoNumber"
                  type="number"
                  {...register("informativeNumber")}
                  placeholder="N°..."
                  autoComplete="off"
                  defaultValue={""}
                  style={{
                    borderRadius: "6px",
                    borderColor: "#d5d5d5",
                    border: "1px solid #d5d5d5",
                  }}
                  className="m-b20"
                />
                <input
                  name="observation"
                  type="text"
                  {...register("observation")}
                  placeholder="Observação..."
                  autoComplete="off"
                  defaultValue={""}
                  style={{
                    borderRadius: "6px",
                    borderColor: "#d5d5d5",
                    border: "1px solid #d5d5d5",
                  }}
                  className="m-b20"
                />
                <div>
                  <button
                    name="submit"
                    type="submit"
                    className="btn btn-primary btnhover p-2 save-button"
                  >
                    Criar observação
                  </button>
                  <button
                    className="btn btn-primary btnhover m-r10 p-2 save-button"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../contexts/auth";
import showPopup from "../components/PopUp";
import api from "../services/config.url";
import PageTitle from "../layouts/PageTitle";
import { useForm } from "react-hook-form";

const ChangePassword = () => {
  const { setLoading } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();
  const { recoveryCode } = useParams();

  const changePassword = async (data) => {
    setLoading(true);
    try {
      const response = await api.post(
        `customers/auth/password-recovery/${recoveryCode}`,
        {
          password: data.password,
        }
      );

      showPopup(response?.data, "success");
    } catch (error) {
      const genericError = "Erro ao alterar a senha";
      return showPopup(error?.response?.data?.message || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="header-padding-main">
      <PageTitle parentPage="CJ" childPage="Troca de senha" />
      <div className="recovery-password-container">
        <form onSubmit={handleSubmit(changePassword)}>
          <div className="mb-5">
            <label className="label-title">Nova senha *</label>
            <input
              {...register("password")}
              required="true"
              className="form-control"
              placeholder="Sua nova senha"
              type="text"
            />
          </div>

          <div className="text-right">
            <button type="submit" className="btn btn-primary btnhover me-2">
              Alterar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ChangePassword;

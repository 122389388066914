import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/pt-br";
dayjs.extend(utc);
dayjs.extend(timezone);

const InputDateGen = (props) => {
  const {
    value,
    onChange,
    className,
    placeholder,
    mask = "99/99/9999",
  } = props;

  const [date, setDate] = useState(value || "");

  useEffect(() => {
    if (date) {
      setDate(dayjs(value).format("DD/MM/YYYY") || "");
    } else {
      setDate(value || "");
    }
  }, [value]);

  const handleUpdate = (e) => {
    let inputValue = e.target.value;
    let newDate = null;
    if (inputValue.length === 10) {
      newDate = dayjs(inputValue, "DD/MM/YYYY").toDate();
    }
    setDate(newDate);
    onChange && onChange(newDate);
  };

  return (
    <div className="relative">
      <InputMask
        className={`${className}`}
        mask={mask}
        maskChar={null}
        placeholder={placeholder}
        size="sm"
        value={date}
        borderRadius={5}
        onChange={handleUpdate}
      />
    </div>
  );
};

export default InputDateGen;

import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../contexts/auth";
import api from "../../services/config.url";
import showPopup from "../../components/PopUp";

export default function ModalCoupon({ open, onClose, date, data }) {
  const { setLoading } = useContext(AuthContext);
  const [contentData, setContentData] = useState([]);
  const [response, setResponse] = useState([]);
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 50,
    groupBy: false,
    date: date,
  });

  useEffect(() => {
    if (query.date !== date) query.date = date;
    if (contentData !== data) setContentData(data);
  }, [data, date, query]);

  // useEffect(() => {
  //   const formattedQuery = new URLSearchParams(query).toString();
  //   setLoading(true);
  //   api
  //     .get(`/customers/message/feedbacks?${formattedQuery}`)
  //     .then((response) => {
  //       setResponse(response.data);
  //     })
  //     .catch((error) => {
  //       const BackendError = error?.response?.data?.message;
  //       const genericError = "Não foi possível concluir a busca por CEP";
  //       showPopup(BackendError || genericError, "error");
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [query, date]);

  if (!open) {
    document.body.style.overflow = "scroll";
    return null;
  }
  if (open) {
    document.body.style.overflow = "hidden";
  }
  if (!open) return null;

  return (
    <>
      <div className="backgroundDark" onClick={onClose}></div>
      <div className="box-coupons">
        <h5>Sugestão:</h5>
        <p>{contentData?.sugestion}</p>
      </div>
    </>
  );
}

import React, { useState, createContext, useEffect, useCallback } from "react";
import api from "../services/config.url";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import showPopup from "../components/PopUp";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState(null);
  const [sizeCart, setSizeCart] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  let isAuthenticated = !!user;

  const signIn = useCallback(
    async (data, redirect, route) => {
      Cookies.remove("token");
      setLoading(true);

      try {
        const response = await api.post("customers/auth/signin", {
          email: data.email,
          nickname: data.nickname,
          password: data.password,
        });
        const date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);

        Cookies.set("token", response.data.token, { expires: date });

        api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

        setUser(response.data.profile);

        if (redirect && redirect?.redirection && !route) {
          navigate.goBack();
        }
        if (redirect && redirect?.redirection && route) {
          navigate.push(`${route}`);
        }
      } catch (error) {
        const BackendError = error?.response?.data?.message;
        const genericError =
          "Erro ao autenticar o usuário, contate o administrador do sistema";
        showPopup(BackendError || genericError, "error");
        navigate.push("/acesso");
        return error;
      } finally {
        setLoading(false);
      }
    },
    [navigate, location.state]
  );
  const signOut = useCallback(() => {
    setLoading(true);
    setUser(null);
    Cookies.remove("token");
    navigate.push("/acesso");

    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, [navigate]);

  useEffect(() => {
    setLoading(true);
    const token = Cookies.get("token");
    if (token) {
      api
        .get("/customers/profile")
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          navigate.push("/acesso");
          Cookies.remove("token");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      api
        .post("/customers/cart")
        .then((response) => {
          setCart(response.data);
          // setSizeCart(response.data.items.length);
        })
        .catch((error) => {
          showPopup(error.response.data.message, "alert");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isAuthenticated, sizeCart]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        setCart,
        setSizeCart,
        setLoading,
        user,
        cart,
        sizeCart,
        isAuthenticated,
      }}
    >
      {loading && (
        <div className="loader">
          <span className="loader__element"></span>
          <span className="loader__element"></span>
          <span className="loader__element"></span>
        </div>
      )}

      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../contexts/auth";
import GlobalContext from "../contexts/global";
import { Dropdown } from "react-bootstrap";
//images
import logo from "./../assets/images/logo-sem-texto.png";

import Collapse from "react-bootstrap/Collapse";
// import { MenuListArray2 } from "./MenuListArray2";
import showPopup from "../components/PopUp";

function Header() {
  const { isAuthenticated, user, signOut, sizeCart, setSizeCart, cart } =
    useContext(AuthContext);
  const { global } = useContext(GlobalContext);
  const navigate = useHistory();
  const [headerFix, setheaderFix] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [MenuListArray2, setMenuListArray2] = useState([]);
  const [active, setActive] = useState("Home");
  const showSidebar = () => setSidebarOpen(!sidebarOpen);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, [headerFix]);

  useEffect(() => {
    if (!cart) return;
    setSizeCart(cart.items.length);
  }, [cart]);

  const handleMenuActive = (status) => {
    setActive(status);
    if (active === status) {
      setActive("");
    }
  };

  useEffect(() => {
    if (!global?.headerOptions) return;

    const headerOpt = [
      {
        title: "Lista de Cadernos",
        to: "./lista-de-cadernos",
      },
      {
        title: "Combos",
        to: "./combos",
      },
      {
        title: "Fale conosco",
        to: "./contato",
      },
      {
        title: "Colaborações",
        to: "./colaboracoes",
      },
    ];

    const tip = global?.headerOptions.find((x) => x.type === "TIP");
    const health = global?.headerOptions.find((x) => x.type === "HEALTH");

    if (tip.actived) {
      headerOpt.splice(tip.ordenation, 0, {
        title: tip.name,
        to: "./dicas",
      });
    }
    if (health.actived) {
      headerOpt.splice(health.ordenation, 0, {
        title: health.name,
        to: "./saude",
      });
    }

    setMenuListArray2(headerOpt);
  }, [global]);

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <header className="site-header mo-left header style-1">
        <div className="header-info-bar">
          <div
            className="container clearfix p-0 limite-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <!-- Website Logo --> */}
            <div className={`logo-header logo-dark logo-desk`}>
              <Link to={""}>
                <img src={logo} alt="logo" />
              </Link>

              <div className="list-menu-desktop">
                <ul className="sub-menu">
                  <li>
                    {" "}
                    <Link to={"/lista-de-cadernos"}>
                      {" "}
                      Lista de Cadernos{" "}
                    </Link>{" "}
                  </li>
                  {global.comboActived && (
                    <li>
                      {" "}
                      <Link to={"/combos"}> Combos </Link>{" "}
                    </li>
                  )}
                  {global?.headerOptions?.some(
                    (x) => x.type === "TIP" && x?.actived
                  ) && (
                    <li>
                      {" "}
                      <Link to={"/dicas"}>
                        {" "}
                        {
                          global?.headerOptions.find((x) => x.type === "TIP")
                            ?.name
                        }{" "}
                      </Link>{" "}
                    </li>
                  )}
                  {global?.headerOptions?.some(
                    (x) => x.type === "HEALTH" && x?.actived
                  ) && (
                    <li>
                      {" "}
                      <Link to={"/saude"}>
                        {" "}
                        {
                          global?.headerOptions((x) => x.type === "HEALTH")
                            ?.name
                        }{" "}
                      </Link>{" "}
                    </li>
                  )}
                  <li>
                    {" "}
                    <Link to={"/colaboracoes"}> Colaboradores </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={"/meus-cadernos"}
                      className="btn btn-primary w-100 btnhover btn-sm m-r50 fw-600 secondary"
                    >
                      MEUS CADERNOS
                    </Link>
                  </li>
                  {isAuthenticated ? (
                    <li>
                      <Link
                        to={"/meus-livros"}
                        className="btn btn-primary w-100 btnhover btn-sm m-r50 fw-600 btn-width-fixed"
                      >
                        MEUS ESTUDOS
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={"#"}
                        onClick={async () => {
                          await navigate.push("/meus-livros");
                          await navigate.push("/acesso");
                          showPopup("Faça seu login", "alert");
                        }}
                        className="btn btn-primary w-100 btnhover btn-sm m-r50 fw-600"
                      >
                        MEUS ESTUDOS
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            {/* <!-- EXTRA NAV --> */}
            <div className="extra-nav">
              <div className="extra-cell">
                <ul className="navbar-nav header-right">
                  {isAuthenticated && (
                    <>
                      <div className="cart m-b20 m-t20">
                        <Link to={"/carrinho"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="40px"
                            viewBox="0 0 18 24"
                            width="40px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                          </svg>
                          <span className="badge">{sizeCart}</span>
                        </Link>
                      </div>
                      <Dropdown
                        as="li"
                        className="nav-item dropdown profile-dropdown  ms-4"
                      >
                        <Dropdown.Toggle as="div" className="nav-link i-false">
                          <div className="profile-info">
                            <Link to={"/perfil"}>
                              <button className="btn-primary btnhover btn-sm name-user">
                                {user.nickname || "-"}
                              </button>
                            </Link>
                          </div>
                        </Dropdown.Toggle>
                      </Dropdown>
                    </>
                  )}
                  {!isAuthenticated && (
                    <div className="profile-info m-l20">
                      <Link
                        to={"/acesso"}
                        className="btn btn-primary w-100 btnhover btn-sm p-r50 "
                      >
                        Cadastro / Login
                      </Link>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`mobilesticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <div className="main-bar clearfix">
            <div className="container clearfix">
              {/* <!-- Website Logo --> */}
              <div className="logo-header logo-dark logo-mob">
                <Link to={""}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              {/* <!-- Nav Toggle Button --> */}
              <button
                className={`navbar-toggler collapsed navicon justify-content-end ${
                  sidebarOpen ? "open" : ""
                }`}
                onClick={showSidebar}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              {/* <!-- EXTRA NAV --> */}

              {/* <!-- Main Nav --> */}
              <div
                className={`header-nav navbar-collapse collapse justify-content-start ${
                  sidebarOpen ? "show" : ""
                }`}
                id="navbarNavDropdown"
              >
                <div className="logo-header logo-dark">
                  <Link to={""} onClick={() => setSidebarOpen(false)}>
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <form className="search-input">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Text input with dropdown button"
                      placeholder="Faça uma busca"
                    />
                    <button className="btn" type="button">
                      <i className="flaticon-loupe"></i>
                    </button>
                  </div>
                </form>
                <ul className="nav navbar-nav">
                  {MenuListArray2.map((data, index) => {
                    if (data.to === "./combos" && !global.comboActived) return;
                    if (data.to === "./headlines" && !global.headlineActived)
                      return;
                    return (
                      <li
                        onClick={() => setSidebarOpen(false)}
                        key={index}
                        className={`${
                          active === data.title && data.content
                            ? "sub-menu-down open"
                            : data.content
                            ? "sub-menu-down"
                            : ""
                        } `}
                      >
                        <Link
                          to={data.content ? "#" : data.to}
                          onClick={() => handleMenuActive(data.title)}
                        >
                          <span>{data.title}</span>
                        </Link>
                        {data.content && (
                          <Collapse in={active === data.title ? true : false}>
                            <ul className="sub-menu">
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to={data.to}>{data.title}</Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        )}
                      </li>
                    );
                  })}
                  {!isAuthenticated && (
                    <>
                      <Link
                        to={"#"}
                        onClick={async () => {
                          await navigate.push("/meus-livros");
                          await navigate.push("/acesso");
                          showPopup("Faça seu login", "alert");
                          setSidebarOpen(false);
                        }}
                        className="btn btn-primary w-100 btnhover btn-sm m-r50 fw-600"
                      >
                        MEUS ESTUDOS
                      </Link>
                      <Link
                        to={"acesso"}
                        onClick={() => setSidebarOpen(false)}
                        className="btn btn-primary w-100 btnhover btn-sm m-t50 "
                      >
                        Cadastro / Login
                      </Link>
                    </>
                  )}
                  {isAuthenticated && (
                    <>
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        to={"meus-livros"}
                        className="btn btn-primary w-100 btnhover btn-sm m-r50 fw-600 mt-5"
                      >
                        MEUS ESTUDOS
                      </Link>
                      <div className="cart m-b0 m-t20">
                        <Link
                          to={"/carrinho"}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="40px"
                            viewBox="0 0 18 24"
                            width="40px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
                          </svg>
                          <span className="badge">{sizeCart}</span>
                        </Link>
                      </div>
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        to={"/perfil"}
                        className="btn btn-primary w-100 btnhover btn-sm m-t20 "
                      >
                        {user.nickname || "Usuario"}
                      </Link>

                      <Link
                        to={"acesso"}
                        className="btn btn-primary w-100 btnhover btn-sm m-t20 "
                        onClick={signOut}
                      >
                        Sair
                      </Link>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Header;

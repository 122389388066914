import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import AuthContext from "../../../contexts/auth";
import showPopup from "../../PopUp";
import CreateRevisionsModal from "../modal-revisions/create-revisions-book";
import HistoricRevisionsModal from "../modal-revisions/historic-revisions-modal";
import PopUpModal from "../modal-revisions/modal-revision-color";
import api from "../../../services/config.url";
import InputDate from "../../input/inputDate";
import ordanation from "../../../assets/images/ordenacao2.png";
import { Input } from "antd";
import { debounce } from "@mui/material";

export default function RevisionsModal({}) {
  const { setLoading } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenHistoric, setIsOpenHistoric] = useState(false);
  const [isOpenColor, setIsOpenColor] = useState(false);
  const [response, setResponse] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [historicData, setHistoricData] = useState();
  const [openPop, setOpenPop] = useState(true);
  const [selectedButton, setSelectedButton] = useState(null);
  const [isLoadingContainer, setIsLoadingContainer] = useState(false);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({
    ordenationParam: "sku",
    ordenationType: -1,
    seeAll: false,
    name: "",
  });

  const delayedQuery = useRef(
    debounce((q) => setQuery({ ...query, name: q }), 500)
  );

  const onFilter = (q) => {
    setSearch(q);
    delayedQuery.current(q);
  };

  const encodedQuery = Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");

  const fetchData = useCallback(async () => {
    if (isOpen || isOpenHistoric) return;
    try {
      setLoading(true);
      setResponse([]);
      const res = await api.get(`/customers/revisions-book?${encodedQuery}`);
      setResponse(res.data);
      setFiltered(res.data);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao listar os cadernos no painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  }, [query, setLoading, isOpen]);

  useEffect(() => {
    fetchData();
  }, [fetchData, isOpen]);

  const handleHistoric = useCallback((event) => {
    setHistoricData(event);
    setIsOpenHistoric(true);
  }, []);

  const updateCurrent = async (body) => {
    const { id, data, type, item } = body;
    let payload = {
      ...item,
      type,
      product: item?.product?._id || null,
      parent: item?.parent || null,
    };
    if (type !== "color" && type !== "hidden" && type !== "seeAll") {
      const dates = JSON.parse(data);
      if (dates.oldDate === dates.newDate) return;
      payload.currentDate = dates.newDate;
      payload.type = "currentDate";
    }
    if (type === "color") {
      payload.color = data;
    }
    if (type === "hidden" || type === "seeAll") {
      payload.hidden = data;
    }

    const url =
      type !== "seeAll"
        ? `/customers/revisions-book/revision/${id}`
        : `/customers/revisions-book/revision/null`;

    try {
      setLoading(true);
      await api.patch(`${url}`, payload);
      const message = "Registro atualizado com sucesso.";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao editar o carderno no painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      setSelectedButton(null);
      await fetchData();
    }
  };

  const deleteHistoricBook = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `/customers/revisions-book/historic/${id}`
      );
      setResponse(response.data);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao deletar o histórico do caderno no painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      handleCancel();
    }
  }, []);

  const handleCancel = useCallback(async () => {
    setIsOpen(false);
    setIsOpenHistoric(false);
    setIsOpenColor(false);
    await fetchData();
  }, [isOpen, isOpenHistoric]);

  const Body = () => {
    return (
      <div>
        <div className="actions-books">
          <div>
            <button
              className="btn btn-top-revisions btnhover m-b10 m-r10"
              style={{ minWidth: "140px" }}
              onClick={() => setQuery({ ...query, seeAll: true })}
            >
              Todos os cadernos
            </button>
            <button
              style={{ minWidth: "140px", width: "140px" }}
              className="btn btn-top-revisions btnhover m-b10"
              onClick={() => setQuery({ ...query, seeAll: false })}
            >
              Meus cadernos
            </button>
          </div>
          <button
            style={{ minWidth: "110px", marginLeft: "1px" }}
            className="btn btn-primary btnhover m-b10"
            onClick={() => setIsOpen(true)}
          >
            Novo registro
          </button>
        </div>

        <div className="revisions-top-bar m-t30 m-b10">
          <div className="text-center">
            <button className="ordenation-button m-0">
              <span className="d-none-desk">Sku</span>
              <img
                src={ordanation}
                onClick={() =>
                  setQuery({
                    ...query,
                    ordenationParam: "sku",
                    ordenationType: query.ordenationType === -1 ? 1 : -1,
                  })
                }
              />
            </button>
          </div>
          <p className="text-center m-0 weight d-none-mob">Caderno / Matéria</p>
          <div className="text-center">
            <button className="ordenation-button m-0">
              <span className="d-none-desk">Data</span>
              <img
                src={ordanation}
                onClick={() =>
                  setQuery({
                    ...query,
                    ordenationParam: "currentDate",
                    ordenationType: query.ordenationType === -1 ? 1 : -1,
                  })
                }
              />
            </button>
          </div>
          <div className="m-r10">
            <Input
              placeholder="Buscar caderno..."
              allowClear
              autoFocus="autoFocus"
              value={search}
              onChange={(e) => onFilter(e.target.value)}
            />
          </div>
        </div>

        <div
          className={`container-books-itens ${
            selectedButton && "disable-scroll"
          }`}
        >
          {response ? (
            <>
              {isLoadingContainer ? (
                <div
                  style={{ margin: "20px auto" }}
                  className="loadingContainer"
                ></div>
              ) : (
                <React.Fragment>
                  {filtered.map((item, index) => (
                    <React.Fragment key={index}>
                      {!item.hidden && (
                        <div key={index} className="container-revision">
                          <div
                            className={`revisions-book-content ${
                              item.bought
                                ? "revisions-columns-active"
                                : "revisions-columns-disable"
                            }`}
                          >
                            <p className="info-container-date item1">
                              <div>{item.product?.sku || item?.sku || "-"}</div>
                            </p>
                            <p className="item2">
                              {item.product?.name || item?.name || "-"}
                            </p>

                            {item.bought ? (
                              <>
                                <InputDate
                                  item={item}
                                  updateCurrent={updateCurrent}
                                  className={`style-input-date item3
                                ${item.color === "red" && "red-date"}
                                ${item.color === "green" && "green-date"}
                                ${item.color === "yellow" && "yellow-date"}
                                ${!item.color && "white"}`}
                                />
                                <PopUpModal
                                  item={item}
                                  onCancel={handleCancel}
                                  updateCurrent={updateCurrent}
                                  isModalOpen={isOpenColor}
                                />
                                <button
                                  onClick={() => handleHistoric(item)}
                                  className="text-center btn btn-primary btnhover p-1 item5"
                                >
                                  Histórico
                                </button>
                                <div
                                  className="text-center item6"
                                  // onClick={() =>
                                  //   updateCurrent(item._id, true, "hidden")
                                  // }
                                >
                                  <button
                                    style={{ width: "36px", height: "33px" }}
                                    className="btnCad deleteBtnCad btnhover p-0"
                                    onClick={() => deleteHistoricBook(item._id)}
                                  >
                                    X
                                  </button>
                                  {/* <img src={ocultar} width={"20px"} /> */}
                                </div>
                              </>
                            ) : (
                              <p className="text-center itemNo">
                                {" "}
                                Caderno não adquirido
                              </p>
                            )}
                          </div>
                          {/* <button className="btnCad deleteBtnCad btnhover">X</button> */}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </>
          ) : (
            <React.Fragment>
              <div
                style={{ margin: "20px auto" }}
                className="loadingContainer"
              ></div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <CreateRevisionsModal onCancel={handleCancel} isModalOpen={isOpen} />
      <HistoricRevisionsModal
        data={historicData}
        onCancel={handleCancel}
        isModalOpen={isOpenHistoric}
      />
      <Body />
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../contexts/auth";
import { useForm } from "react-hook-form";
import showPopup from "../../PopUp";
import { Select } from "antd";
import api from "../../../services/config.url";

export default function CreateRevisionsModal({ onCancel, isModalOpen }) {
  const { setLoading } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState([]);
  const [productSelected, setProductSelected] = useState(null);
  const [type, setType] = useState("PARENT"); // Defina o tipo padrão aqui
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    // Carrega os dados do dropdown ao abrir o modal
    if (isModalOpen && type === "REVISION") {
      getDropDownBooks();
    }
  }, [isModalOpen, type]);

  const getDropDownBooks = async () => {
    try {
      const list = await api.get(
        `/customers/revisions-book/drop-down-products`
      );
      list.data.sort((a, b) => a.sku - b.sku);

      const dropDown = list.data.map((x) => ({
        label: `${x.sku} - ${x?.name}`,
        value: JSON.stringify(x),
      }));

      setFilteredData(dropDown);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de produtos";
      showPopup(BackendError || genericError, "error");
    }
  };

  const postRevision = async (form) => {
    const { observation, bookName } = form;

    let payload = {
      currentDate: null,
      color: null,
      type,
      observation,
      startDate: new Date(),
    };

    if (!productSelected && type === "REVISION") {
      return showPopup("Selecione um caderno para criar uma revisão", "alert");
    }

    if (productSelected && type === "REVISION") {
      const productParsed = JSON.parse(productSelected);
      payload = {
        ...payload,
        sku: productParsed.sku,
        name: productParsed.name,
        product: productParsed?._id || null,
        parent: productParsed?.parent || null,
      };
    }

    if (type === "PARENT") {
      payload = {
        ...payload,
        sku: "0000",
        name: bookName,
        parent: null,
        observation: null,
        product: null, // Defina o valor adequado para o tipo "PARENT"
      };
    }
    try {
      setLoading(true);
      await api.post(`/customers/revisions-book`, payload);
      const message =
        type === "PARENT"
          ? "Novo registro criado com sucesso"
          : "Nova revisão criada com sucesso";
      showPopup(message, "success");
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao cadastrar um caderno no painel";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
      getDropDownBooks(); // Atualiza os dados do dropdown após a criação
      handleCancel();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSelectChange = (option) => {
    setProductSelected(option);
  };

  const handleSelectType = (value) => {
    setType(value);
    setValue("typeForm", value); // Atualiza o valor do input escondido
  };

  return (
    <>
      {isModalOpen && (
        <div className="container-modal-books">
          <div className="bg-modal">
            <form onSubmit={handleSubmit(postRevision)}>
              <div className="create-modal-layout">
                <label className="m-b10">Tipo de registro</label>
                <Select
                  placeholder="Tipo de registro"
                  className="m-b20"
                  value={type}
                  onChange={handleSelectType}
                  options={[
                    { label: "Novo registro", value: "PARENT" },
                    { label: "Revisão", value: "REVISION" },
                  ]}
                />
                {type === "PARENT" && (
                  <input
                    name="bookName"
                    {...register("bookName")}
                    type="text"
                    autoComplete="off"
                    placeholder="Número do Caderno / Matéria / Assunto..."
                    className="m-b20"
                    style={{
                      borderRadius: "6px",
                      borderColor: "#d5d5d5",
                      border: "1px solid #d5d5d5",
                    }}
                  />
                )}
                {type === "REVISION" && (
                  <Select
                    showSearch
                    placeholder="Selecione o caderno"
                    className="m-b20"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    options={filteredData}
                  />
                )}
                {type === "REVISION" && (
                  <input
                    name="observation"
                    type="text"
                    {...register("observation")}
                    placeholder="Observação..."
                    autoComplete="off"
                    defaultValue={""}
                    style={{
                      borderRadius: "6px",
                      borderColor: "#d5d5d5",
                      border: "1px solid #d5d5d5",
                    }}
                    className="m-b20"
                  />
                )}
                <div>
                  <button
                    name="submit"
                    type="submit"
                    className="btn btn-primary btnhover p-2 save-button"
                  >
                    Criar registro
                  </button>
                  <button
                    className="btn btn-primary btnhover m-r10 p-2 save-button"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";

//Components
import HomeMainSlider from "../components/Home/HomeMainSlider";
import RecomendedSlider from "./../components/Home/RecomendedSlider";
import FeaturedSlider from "./../components/Home/FeaturedSlider";
import TestimonialSlider from "./../components/Home/TestimonialSlider";
import NewsLetter from "./../components/NewsLetter";
import { useDebounce } from "../hooks/useDebounce";

const iconBlog = [
  {
    title: "Leis Comentadas",
    iconClass: "1",
    preview:
      "A CF, os Códigos e as leis de um jeito único, como você nunca viu!",
    description:
      "A CF, os Códigos e as leis de um jeito único, como você nunca viu! Legislação comentada com explicações precisas, questões estratégicas e jurisprudência atualizada! Nossas leis comentadas efetivamente economizam o tempo do leitor e antecipam a sua posse no sonhado cargo público!",
  },
  {
    title: "Doutrina essencial",
    iconClass: "2",
    preview:
      "Nossa parte teórica sintetiza as lições dos melhores manuais doutrinários para concursos do mercado!",
    description:
      "Nossa parte teórica sintetiza as lições dos melhores manuais doutrinários para concursos do mercado! Mas para os Cadernos, só vai o que é essencial para as provas! Resumimos milhares de páginas de doutrina em explicações simples e objetivas, selecionadas a dedo, visando o alto desempenho em provas e a aprovação em concursos públicos!",
  },
  {
    title: "Questões de Concursos",
    iconClass: "3",
    preview:
      "Os Cadernos seguem o tempo todo testando o conhecimento estudado!",
    description:
      "Os Cadernos seguem o tempo todo testando o conhecimento estudado! São milhares de questões estratégicas, de provas já realizadas ou elaboradas pela equipe, dentro da legislação ou das matérias teóricas. Escolhemos o melhor método para a sua total absorção do conteúdo, gerando fluidez na leitura e aumentando a memorização e a percepção das pegadinhas!",
  },
  {
    title: "Jurisprudência atualizada",
    iconClass: "4",
    preview:
      "Praticamente todos os concursos exigem o conhecimento dos informativos do STJ e STF!",
    description:
      "Praticamente todos os concursos exigem o conhecimento dos informativos do STJ e STF! Assim, sintetizamos milhares de páginas de informativos do STF/STJ, do presente ano e dos anos anteriores, nos respectivos assuntos dos Cadernos, que seguem sob constante revisão e atualização. O estudo contextualizado da jurisprudência faz o leitor sair na frente!",
  },
];

function Index1() {
  const [search, setSearch] = useState("");
  const readyToSearch = useDebounce(search, 500);

  return (
    <>
      <div className="page-content bg-white">
        <div className="main-slider style-1 banner-img">
          <HomeMainSlider />
        </div>
        <section className="content-inner-1 reccomend">
          <div className="search-home">
            <span className="search-icon">
              <i className="fa fa-search"></i>
            </span>
            <input
              onFocus={() => setFocused(false)}
              onBlur={() => setFocused(true)}
              type="text"
              style={{ height: "60px", textAlign: "center" }}
              className=" m-auto"
              aria-label="Text input with dropdown button"
              placeholder={"Qual matéria / lei / assunto você está procurando?"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title m-t50">VEJA TODAS AS MATÉRIAS:</h2>
            </div>
            <RecomendedSlider search={readyToSearch} />
          </div>
        </section>

        <section className="content-inner-2 bg-grey p-t40">
          <div className="container m-b40">
            <div className="row">
              {iconBlog.map((data, index) => (
                <div key={index} className="col-lg-3 col-sm-6 description">
                  <div className="icon-bx-wraper style-1 m-b90 m-t20 text-center">
                    <div className="icon-bx-sm m-b10">
                      <span> {data.iconClass} </span>
                    </div>
                    <div className="icon-content">
                      <h5 className="dz-title m-b10"> {data.title} </h5>
                      <p className="description preview">{data.preview}</p>
                      <p className="description completeText">
                        {data.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="content-inner-1 p-0 reccomend">
          <div className="container-fluid p-0 bg-grey">
            <FeaturedSlider />
          </div>
        </section>

        <section className="content-inner-2 m-b30 testimonial-wrapper">
          <TestimonialSlider />
        </section>

        <NewsLetter />
        <div className="col-12 m-t30 banner-whats">
          <div className="col-11 limit-of-text">
            <h2>Participe de nosso grupo de Revisão do Whatsapp!</h2>
            <p>
              Compartilhamos <strong>questões</strong> de prova selecionadas a
              dedo, notícias sobre concursos e cupons de{" "}
              <strong> descontos!</strong>
            </p>
            <h2>
              <a href="https://chat.whatsapp.com/FdSrgSa0QA4HHaQIlB2gw8">
                {" "}
                Clique aqui e ingresse no Grupo de Revisão!
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
export default Index1;

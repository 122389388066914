import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";

import bannerAboutUs from "./../../assets/images/banner/banner-sem-traco.png";

function FeaturedSlider() {
  return (
    <>
      <div className="row justify-content-center box-about-us-home">
        <div className="right-flag flag-style"></div>
        <div className="left-flag flag-style"></div>
        <Link to="sobre-nos">
          {" "}
          <img src={bannerAboutUs} />{" "}
        </Link>
      </div>
    </>
  );
}
export default FeaturedSlider;

import { BrowserRouter, Route, Switch } from "react-router-dom";
//Contexts

import { AuthProvider } from "../contexts/auth";
import { GlobalProvider } from "../contexts/global";
//layouts
import Header from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import Main from "../layouts/Main";
//Pages
import Home from "./Home";
import AboutUs from "./AboutUs";
import MyProfile from "./MyProfile";
import MyOrders from "./MyOrders";
import BooksGridView from "./BooksGridView";
import Combos from "./Combos";
import ShopCart from "./ShopCart";
import Wishlist from "./Wishlist";
import Login from "./Login";
import Registration from "./Registration";
import ShopCheckout from "./ShopCheckout";
import ShopDetail from "./ShopDetail";
import BlogDetail from "./BlogDetail";
import ContactUs from "./ContactUs";
import MyBooks from "./MyBooks";
import BooksAcquired from "./BooksAcquired";
import ErrorPage from "./ErrorPage";
import UnderConstruction from "./UnderConstruction";
import ComingSoon from "./ComingSoon";
import Sugestion from "./Sugestion";
import Collaborations from "./Collaborations";
import MyCollaborations from "./MyCollaborations";
import Headlines from "./Headlines";
import Tips from "./Tips";
import ScrollToTop from "./../layouts/ScrollToTop";
import ChangePassword from "./ChangePassword";

//images
import logo from "./../assets/images/logo2.png";

function Index(props) {
  const baseName = "";

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
    <BrowserRouter basename={baseName}>
      <div className="page-wraper">
        <Switch>
          <Route path="/error-404" component={ErrorPage} />
          <Route path="/under-construction" component={UnderConstruction} />
          <Route path="/coming-soon" component={ComingSoon} />
          <Route>
            <GlobalProvider>
              <AuthProvider>
                <Header />
                <Main>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/sobre-nos" exact component={AboutUs} />
                    <Route path="/perfil" exact component={MyProfile} />
                    <Route path="/pedidos" exact component={MyOrders} />
                    <Route
                      path="/trocar-senha/:recoveryCode?"
                      exact
                      component={ChangePassword}
                    />
                    <Route
                      path="/meus-livros/:scroll?"
                      exact
                      component={MyBooks}
                    />
                    <Route
                      path="/meus-cadernos"
                      exact
                      component={BooksAcquired}
                    />
                    <Route
                      path="/colaboracoes"
                      exact
                      component={Collaborations}
                    />
                    <Route
                      path="/minhas-colaboracoes"
                      exact
                      component={MyCollaborations}
                    />
                    <Route
                      path="/lista-de-cadernos"
                      exact
                      component={BooksGridView}
                    />
                    <Route path="/combos" exact component={Combos} />
                    <Route path="/carrinho" exact component={ShopCart} />
                    <Route path="/wishlist" exact component={Wishlist} />
                    <Route path="/acesso" exact component={Login} />
                    <Route path="/cadastre-se" exact component={Registration} />
                    <Route
                      path="/finalizar-compra"
                      exact
                      component={ShopCheckout}
                    />
                    <Route
                      path="/livro-detalhes/:productId"
                      exact
                      component={ShopDetail}
                    />
                    <Route path="/blog-detail" exact component={BlogDetail} />
                    <Route path="/contato" exact component={ContactUs} />
                    <Route path="/saude" exact component={Headlines} />
                    <Route path="/dicas" exact component={Tips} />
                    <Route
                      path="/sugestoes/cadernos/:sku"
                      exact
                      component={Sugestion}
                    />
                  </Switch>
                </Main>
                <Footer footerChange="style-1" logoImage={logo} />
              </AuthProvider>
            </GlobalProvider>
          </Route>
        </Switch>
        <ScrollToTop />
      </div>
    </BrowserRouter>
  );
}
export default Index;
